<template>
  <div class="outer-div">
    <div class="boxes-div">
      <Icon @click="gotoFirstPage()" v-if="currentPage > 1" icon="majesticons:chevron-double-left-line" width="24px" :inline="true" class="navigation__icon"></Icon>
      <Icon @click="previousPage()" v-if="currentPage > 1" icon="majesticons:chevron-left-line" width="24px" :inline="true" class="navigation__icon"></Icon>
      <div v-for="pageNumber in displayedPages" :key="pageNumber"
      :class="[pageNumber === currentPage ? 'selected-page' : 'unselected-page', { 'three-digits': pageNumber >= 100 }]" 
      @click="pageClicked(pageNumber)">
        {{ pageNumber }}
      </div>
      <Icon @click="nextPage()" v-if="currentPage < numberOfPages" icon="majesticons:chevron-right-line" width="24px" :inline="true" class="navigation__icon"></Icon>
      <Icon @click="gotoLastPage()" v-if="currentPage < numberOfPages" icon="majesticons:chevron-double-right-line" width="24px" :inline="true" class="navigation__icon"></Icon>
    </div>
    <div class="result-text">{{ searchResultText }}</div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'Pagination-Component',
  props: {
    numberOfRecords: {
      type: Number,
      required: true
    },
    word: {
      type: String,
      required: false,
    },
    itemsPerPage: {
      type: Number,
      required: true
    },
    initialPage: {
      type: Number,
      required: true,
      default: 1,
    },    
  },
  watch: {
    initialPage(newVal) {
      this.currentPage = newVal;
    }
  },  
  data() {
    return {
      currentPage: 1
    }
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.numberOfRecords / (this.itemsPerPage || 5));
    },
    displayedPages() {
      let range;

      if (window.innerWidth < 376) {
        range = 6;
      } else if (window.innerWidth < 415) {
        range = 7;
      } else {
        range = 8;
      }

      let start = this.currentPage - Math.floor(range / 2);
      start = Math.max(start, 1);
      start = Math.min(start, Math.max(this.numberOfPages - range + 1, 1));

      const end = Math.min(start + range - 1, this.numberOfPages);

      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
    searchResultText() {
      let result = `${this.numberOfRecords} results`;
      if (this.word?.length) {
        result = `${result} for the word "${this.word}"`;
      }
      return result;
    }
  },
  methods: {
    gotoFirstPage() {
      this.currentPage = 1;
      this.$emit('pageClicked', 1);
    },
    gotoLastPage() {
      this.currentPage = this.numberOfPages;
      this.$emit('pageClicked', this.numberOfPages);
    },
    pageClicked(pageNumber) {
      this.currentPage = pageNumber;
      this.$emit('pageClicked', pageNumber);
    },
    nextPage() {
      if (this.currentPage < this.numberOfPages) {
        this.pageClicked(this.currentPage + 1);
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.pageClicked(this.currentPage - 1);
      }
    }
  },
  components: { Icon }
};
</script>

<style scoped>
.outer-div div {
  margin-bottom: 4px;
}

.boxes-div {
  display: flex;
  align-items: center;
  overflow-x: auto;
  max-width: 390px; /* Adjust based on the total width of n numbers plus navigation icons */
  text-align: center;
}

.boxes-div .navigation__icon {
  width: 24px !important;
  height: 24px !important;
  flex-shrink: 0; /* Prevent the icon from shrinking */
}

.boxes-div div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  cursor: pointer;
  min-width: 24px; 
  height: 24px;
}

.three-digits {
  font-size: 0.7rem; 
}

.selected-page, .unselected-page {
  padding: 4px;
}

.selected-page {
  background: #3A6B35;
}

.unselected-page {
  background: #FFF;
}

.result-text {
  text-align: left;
}

.navigation__icon  {
  cursor: pointer;
}
</style>
