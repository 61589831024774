// Import required libraries
import axios from 'axios';
import { defineStore } from 'pinia';
import { APISettings } from './config.js';

// Create the store
export const useUploadAlbumStore = defineStore('uploadAlbum', {
  state: () => ({
    uploadError: null
  }),
  actions: {
    async uploadFile(data, token) {
      try {
        const headers = {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + token
        };

        const requestOptions = {
          method: 'POST',
          headers: headers,
          data: data
        };
  
        const response = await axios.post(APISettings.baseUrl + '/api/uploadAlbumService/upload', data, requestOptions);
        if (response.data.success) {
          // File uploaded successfully
          this.uploadError = null;
        } else {
          // File upload failed
          this.uploadError = 'Failed to upload the file';
        }
      } catch (error) {
        console.error('Failed to upload the file:', error);
        this.uploadError = 'Failed to upload the file';
      }
    }
  }
});
