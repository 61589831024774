<template>
  <div class="parent__div"  @drop="handleFileDrop" @dragover.prevent="handleDragOver">
    <form @submit.prevent="handleSubmit">
      <div>
        <label for="title">Title:</label>
        <input type="text" id="title" v-model="form.title" required>
      </div>
      <div>
        <label for="description">Description:</label>
        <textarea id="description" v-model="form.description" maxlength="153" required></textarea>
      </div>
      <div v-if="!albumData._id">
        <label for="file">Cover photo:</label>
        <input type="file" id="file" @change="handleFileChange" required>
      </div>
      <div>
        <label for="tags">Tags:</label>
        <input type="text" id="tags" v-model="form.tags" required>
      </div>
      <div v-if="!albumData._id">
        <label for="file-list">Files:</label>
        <input  ref="files" type="file" id="file-list" @change="handleFileListChange" multiple required>
      </div>
      <ul v-if="!albumData._id">
        <li v-for="file in form.files" :key="file.name">{{ file.name }}</li>
      </ul>      
      <button type="submit">Submit</button>
      <button type="button" @click="$emit('onCancelForm', form)">Cancel</button>
    </form>
    <p v-if="store.uploadError" class="error">{{ store.uploadError }}</p>
  </div>
</template>
  
<script>
import { useUploadAlbumStore } from '../../store/uploadAlbum';
import { useAlbumStore } from '../../store/album';

export default {
  setup(props) {
    const albumStore = useAlbumStore();
    const albumData = props?.inserting ? {} : (albumStore.selectedAlbum || {});

    return {
        albumStore,
        albumData,
    };
  },   
  props: {
    loggedInUser: {
      type: Object,
      required: true
    },
    inserting: {
      type: Boolean,
      required: false
    },     
  },
  data() {
    return {
      form: {
        _id: this.albumData._id || null,
        title: this.albumData?.title || '',
        description: this.albumData?.description || '',
        file: null,
        filename: '',
        photographer: null,
        tags: this.albumData?.tags || '',
        files: [], // New array to store selected image files
        // New flag to indicate if the files are valid
        filesValid: false,        
      },
    };
  },
  computed: {
    store() {
      return useUploadAlbumStore();
    }
  },
  methods: {
    handleSubmit() {

      const formData = new FormData();

      if (this.inserting) {
        // Check if the required fields are filled
        if (this.form.title && this.form.description && this.form.file && this.form.tags && this.form.filesValid) {
          
          formData.append('title', this.form.title);
          formData.append('description', this.form.description);
          formData.append('photographer', this.loggedInUser?.id);
          formData.append('tags', this.form.tags);
          formData.append('created', { $date: new Date().toISOString() });
    
          this.$emit('handleSubmit', {formData, albumPhoto: this.form.file, postPhotos: this.form.files});
        } else {
          // Show an error message if any required fields are missing
          this.store.uploadError = 'Please fill in all required fields and select at least one file.';
        }      
      } else {
        
        if (this.form.title && this.form.description && this.form.tags) {
          formData.append('_id', this.form._id);
          formData.append('title', this.form.title);
          formData.append('description', this.form.description);
          formData.append('tags', this.form.tags);

          this.$emit('handleSubmit', {formData});
        } else {
          // Show an error message if any required fields are missing
          this.store.uploadError = 'Please fill in all required fields.';
        } 
      }
      
    },
    handleFileChange(event) {
      this.form.file = event.target.files[0];
      this.form.filename = event.target.files[0]?.filename;
    },
    handleFileListChange(event) {
      // Reset files array and add the selected files
      this.form.files = [];
      const selectedFiles = event.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.form.files.push(selectedFiles[i]);
      }

      // Update the filesValid flag based on the selected files
      this.form.filesValid = this.form.files.length > 0;      
    },    
    handleFileDrop(event) {
      event.preventDefault();
      this.resetDragStyles();
      // Reset files array and add the dropped files
      this.form.files = [];
      const droppedFiles = event.dataTransfer.files;
      for (let i = 0; i < droppedFiles.length; i++) {
        this.form.files.push(droppedFiles[i]);
      }

      this.$refs.files.files = droppedFiles;

      // Update the filesValid flag based on the dropped files
      this.form.filesValid = this.form.files.length > 0;      
    },
    handleDragOver(event) {
      event.preventDefault();
      // Apply border and padding styles while dragging over
      this.$el.style.border = '2px dashed #aaa';
      this.$el.style.padding = '16px';   
    },
    resetDragStyles() {
      // Reset border and padding styles when dragging is over
      this.$el.style.border = '';
      this.$el.style.padding = '';
    }    
  }
};
</script>
  
<style scoped>
button {
  margin-left: 8px;
}

.error {
  color: red;
}

.parent__div {
  background-color: #FFF;
  border-radius: 8px;
  min-width: 302px;
  /* Add the following styles to enable drag-and-drop functionality */
  transition: border 0.2s, padding 0.2s; /* Add a transition for smoother effect */

}

.parent__div.dragging-over {
  border: 2px dashed #aaa;
  padding: 16px;
}

/* Hide the drop indicator when not dragging over the parent div */
.parent__div:not(.drag-over) {
  border: none;
  padding: 0;
}

.parent__div>form>div {
  display: flex;
  flex-wrap: wrap;

}


.parent__div>form>div>label {
  padding: 8px;
  width: 110px;
  text-align: left;
}



.parent__div>form>div>input {
  padding: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 350px;
}

.parent__div>form>div>textarea {
  padding: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 350px;
}

@media (max-width:860px) {
  .parent__div {
    min-width: unset;
    max-width: 100%;
    margin: 8px 0px 0px 8px;
    margin: 8px 0px 0px 8px;

  }

  .parent__div>form>div>input {
    width: 100%;
  }

  .parent__div>form>div>textarea {
    width: 100%;
  }


}


</style>
  