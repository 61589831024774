<template>
    <div class="login__area">

        <div class="inner__login_area">
            <div class="site__text__wrapper">
                <div class="local__toobar">
                    <Icon icon="simple-line-icons:arrow-left-circle" width="16px" :inline="true"  @click="goBack()"></Icon>

                </div>                
                <div class="site__text">
                    <h3>Sign-in to Memoirlens</h3>

                    <div class="text">
                        <p>New to MemoirLens? No problem! Creating a user account is quick and easy. Fill in the required
                            fields,
                            set your preferences, and join our community of photography enthusiasts. By becoming a member,
                            you gain
                            access to exclusive offers, personalized recommendations, and the ability to save your favorite
                            photographs for future reference.</p>
    
                        <p>We prioritize the security and confidentiality of your personal information. Rest assured that
                            your data
                            is encrypted and protected through robust security measures, so you can update your profile with
                            peace
                            of mind.</p>
    
                        <p>Take control of your user profile today and let us enhance your MemoirLens experience. Your
                            moments, your
                            memories, your profile!</p>

                    </div>

                </div>

            </div>

            <div class="contact__form">
                <div class="contact__form__input" v-if="!sendDataStatus && sendDataStatus !== 500">
                    <form @submit.prevent="sendData">
                        <div>
                            <label for="photographer">Photographer:</label>
                            <input type="checkbox" id="photographer" v-model="form.photographer" />
                        </div>

                        <div>
                            <label for="login">Login:</label>
                            <input type="text" id="login" v-model="form.login" required />
                        </div>

                        <div>
                            <label for="password">Password:</label>
                            <input type="password" id="password" v-model="form.password" required />
                        </div>

                        <div>
                            <label for="login">Name:</label>
                            <input type="text" id="name" v-model="form.name" required />
                        </div>

                        <div>
                            <label for="about">About:</label>
                            <textarea id="about" v-model="form.about"></textarea>
                        </div>

                        <div>
                            <label for="email">Email:</label>
                            <input type="email" id="email" v-model="form.email" required />
                        </div>

                        <div>
                            <label for="whatsapp">WhatsApp:</label>
                            <input type="text" id="whatsapp" v-model="form.whatsapp" />
                        </div>

                        <div>
                            <label for="facebook">Facebook:</label>
                            <input type="text" id="facebook" v-model="form.facebook" />
                        </div>

                        <div>
                            <label for="instagram">Instagram:</label>
                            <input type="text" id="instagram" v-model="form.instagram" />
                        </div>

                        <button type="submit">Send</button>
                        <button type="button" @click="cancelSendData()">Cancel</button>
                    </form>

                </div>
                <div v-if="sendDataStatus===201" class="success-message">
                    <h3>Welcome to Memoirlens</h3>
                    <p>Thank you for signing up. We're excited to have you on board! To complete your registration and activate your account, please check your email for a confirmation link. You must confirm your email to log in and access all features.</p>
                    <p>If you don't see the email in your inbox shortly, be sure to check your spam or junk mail folder. For any assistance, feel free to contact our support team.</p>
                    <p class="signature">Warm regards,<br>Cristian Guedes</p>
                </div>
                <div v-if="sendDataStatus===200" class="success-message">
                    <h3>Your data was updated</h3>
                    <p>Your data was successfully sent and update.</p>
                    <p class="signature">Warm regards,<br>Cristian Guedes</p>
                    <p><button type="button" @click="sendDataStatus=null">Continue</button></p>
                </div>                
                <div v-if="sendDataStatus && sendDataStatus!==200 && sendDataStatus !==201" class="error-message">
                    <h3>Oops! Something went wrong.</h3>
                    <p>We're sorry, but your account couldn't be created at this time. Please try again later. We're here to help!</p>
                    <p>Thank you for your patience.</p>
                    <p><button type="button" @click="sendDataStatus=null">Try again</button></p>
                </div>                
                

                <div class="contact__image__wrapper">
                    <img class="contact__image" :src="whatImage" />
                    
                </div>

            </div>


        </div>

        <div class="login__footer">
            <img class="site__logo" src="../assets/android-chrome-192x192.png" />
            <span>Memoirlens</span>
            <span>Every Shot Holds a Memory</span>
        </div>


    </div>
</template>

<script>
import { useUserStore } from '../../store/user';
import { useRouter } from 'vue-router';
import successImage from '@/assets/message_sent.jpg';
import failedImage from '@/assets/message_failed.jpg';
import defaultImage from '@/assets/contact_image_3.jpg';
import { Icon } from '@iconify/vue'

export default {
    setup() {
        const router = useRouter();

        return {
            router,
        };
    },
    data() {
        return {
            form: {
                photographer: false,
                login: '',
                password: '',
                name: '',
                about: '',
                email: '',
                whatsapp: '',
                facebook: '',
                instagram: ''
            },
            sendDataStatus: null,
        };
    },
    computed: {
        userStore() {
            return useUserStore();
        },
        whatImage() {
            if (this.sendDataStatus === 200 || this.sendDataStatus === 201 ) {
                return successImage;

            } else if (this.sendDataStatus === 500) {
                return failedImage;

            }

            return defaultImage;
        },
    },
    mounted() {
        if (this.userStore.loggedInUser) {
            const { loggedInUser } = this.userStore;
            this.form.photographer = loggedInUser.photographer;
            this.form.name = loggedInUser.name;
            this.form.login = loggedInUser.login || '';
            this.form.password = loggedInUser.password || '';
            this.form.about = loggedInUser.about || '';
            this.form.email = loggedInUser.email || '';
            this.form.whatsapp = loggedInUser.whatsapp || '';
            this.form.facebook = loggedInUser.facebook || '';
            this.form.instagram = loggedInUser.instagram || '';
        }
    },
    methods: {
        async sendData() {
            console.log("started sending data");
            // eslint-disable-next-line no-unused-vars
            grecaptcha.ready(() => {
                grecaptcha.execute('6Ld_uZspAAAAAOwcp6W8xAwXQ_vZJzuYMLGc5gnh', { action: 'submit' }).then((token) => {
                    const loginData = {
                        ...this.form,
                        recaptchaToken: token,
                    };
                    this.processSendData(loginData);
                });
            });



        },
        async processSendData(loginData) {
            // Call store method to send data
            const formData = new FormData();
            if (this.userStore?.loggedInUser?.token) {
                formData.append('_id', this.userStore?.loggedInUser?.id);
            }
            formData.append('photographer', loginData.photographer);
            formData.append('login', loginData.login);
            formData.append('password', loginData.password);
            formData.append('name', loginData.name);
            formData.append('about', loginData.about);
            formData.append('email', loginData.email);
            formData.append('whatsapp', loginData.whatsapp);
            formData.append('facebook', loginData.facebook);
            formData.append('instagram', loginData.instagram);
            formData.append('recaptchaToken', loginData.recaptchaToken);

            const result = await this.userStore.sendData(formData, this.userStore?.loggedInUser?.token);

            this.sendDataStatus = result;
        },
        goBack() {
            if (window.history.length > 1) {
                window.history.back();
            } else {
                this.$router.push('/mainPage');
            }
        },
        cancelSendData() {
            this.router.push("/mainPage");
        },
    },
    components: { Icon }
};
</script>

<style scoped>
button:first-of-type {
    margin-right: 8px;
}

/* Success message styling */
.success-message {
  flex: 1;
  margin: 20px auto; 
  text-align: left; 
}

/* Styling the body text for readability */
.success-message p {
  font-size: 16px; 
  line-height: 1.6;
  margin: 10px 0; 
}

/* Styling signature or closing line */
.success-message .signature {
  font-style: italic; 
  margin-top: 20px; 
  font-size: 16px;
}

.error-message {
  flex: 1;
  margin: 20px auto; 
  text-align: left; 
}




.local__toobar {
  display: flex;
  /* float: right; */
  margin-left: auto;
  justify-content: flex-end;
  padding: 8px;
}

.local__toobar  > svg {
  margin: 4px;
  cursor: pointer;
}


.socialmedia-link {
  text-decoration: none; /* No underline by default */
  color: inherit; /* Inherits the color from parent or defaults to the initial color */
}

.socialmedia-link:hover {
  color: #3A6B35; /* Changes the text color on hover */
  text-decoration: underline; /* Underlines the text on hover */
}

.image {
  display: flex;
  width: 100%;
  height: auto;
  max-width: 400px;
  max-height: 400px;
  float: left;
  margin-right: 8px;
}

.contact__image__wrapper {
  display: flex;
  align-items: center;
}

.contact__image {
  display: flex;
  width: 100%;
  height: auto;
  max-width: 400px;
  max-height: 400px;
  margin: 8px;
}

.text__highlight {
  font-size: 1.2em;
  font-weight: bold;
}


.login__me__container>.text {
  text-align: justify;
}

.login__area {
  background-color: #CBD18F;
  padding: 4px;
}

.inner__login_area {
  display: flex;
  flex-wrap: wrap;
  max-width: 1484px;
  margin: auto;
  justify-content: center;
  margin-top: 4px;
  height: auto;


}

.site__text__wrapper {
  background-color: #FFF;
  border-radius: 8px;
}

.contact__form {
  margin: 8px 0 8px  0;
  width: 100%;
  background-color: #FFF;
  border-radius: 8px;
  padding: 8px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.contact__form__input {
  flex: 1;
}

textarea,
input:not([type="checkbox"])  {
  width: calc(100% - 8px);
  margin: 8px 0 8px  0;
  
}

input[type="checkbox"] {
  width: auto; /* Reset width to default for checkboxes */
  margin: 8px 0px 12px 0px;
  display: block; /* Make checkboxes appear on a new line */
}

.site__text {
  padding: 8px;
  text-align: left;
  height: auto;

}

.login__footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin: 8px;
}

.login__footer > span {
  color: #3A6B35;
}

.login__footer > span:nth-child(2) {
  font-size: 1.2em;
}

.login__footer > span:nth-child(3) {
  font-size: 0.8em;
}

.site__logo {
  width: 100%;
  height: auto;
  max-width: 64px;
  margin: 8px;
}

@media (max-width:900px) {
  .contact__form__input {
    flex: auto;
  }

  .contact__image {
    margin-left: auto;
    margin-right: auto;
  }

  .contact__image__wrapper {
    flex: auto;
  }

}


@media (max-width:700px) {
  .image {
    float: none;
    align-items: center;
  }

  .site__text>img {
    margin: auto;

  }

  h3 {
    padding: 0px 0px 0px 0px;
  }
}
</style>