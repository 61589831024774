// comments.js
import axios from 'axios';
import { defineStore } from 'pinia';
import { APISettings } from './config.js';

export const useCommentsStore = defineStore('comments', {
  state: () => ({
    comments: [],
    unpublishedComments: [],
  }),

  actions: {
    async fetchComments(albumId, postId) {
      try {
        const response = await axios.get(`${APISettings.baseUrl}/api/comments/listComments`, {
          params: { albumId, postId },
        });
        this.comments = response.data;
      } catch (error) {
        console.error('Failed to fetch comments:', error);
      }
    },

    async fetchUnpublishedComments(albumId, postId) {
      try {
        const response = await axios.get(`${APISettings.baseUrl}/api/comments/listsUnpublished`, {
          params: { albumId, postId },
        });
        this.unpublishedComments = response.data;
      } catch (error) {
        console.error('Failed to fetch unpublished comments:', error);
      }
    },

    async saveOrUpdateComment(comment) {
      try {
        await axios.post(`${APISettings.baseUrl}/api/comments/saveOrUpdate`, comment);
        await this.fetchComments(comment.albumId, comment.postId);
      } catch (error) {
        console.error('Failed to save or update comment:', error);
      }
    },

    async publishComment(id, token) {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        };

        await axios.put(`${APISettings.baseUrl}/api/comments/publishComment/${id}`, {}, { headers });
        await this.fetchComments(this.albumId, this.postId);
        await this.fetchUnpublishedComments(this.albumId, this.postId);
      } catch (error) {
        console.error('Failed to publish comment:', error);
      }
    },

    async hideComment(id, token) {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        };

        await axios.put(`${APISettings.baseUrl}/api/comments/hideComment/${id}`, {}, { headers });
        await this.fetchComments(this.albumId, this.postId);
        await this.fetchUnpublishedComments(this.albumId, this.postId);
      } catch (error) {
        console.error('Failed to hide comment:', error);
      }
    },

    async deleteComment(id, token) {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        };

        await axios.delete(`${APISettings.baseUrl}/api/comments/deleteComment/${id}`, { headers });
        await this.fetchComments(this.albumId, this.postId);
        await this.fetchUnpublishedComments(this.albumId, this.postId);
      } catch (error) {
        console.error('Failed to delete comment:', error);
      }
    },
  },
});
