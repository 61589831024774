// album.js

import { defineStore } from 'pinia';
import { APISettings } from './config.js';
import axios from 'axios';

export const useAlbumStore = defineStore('album', {
  state: () => ({
    albums: [],
    selectedAlbum: {},
    totalPages: 0,
    numberOfRecords: 0,
    lastPage: null,
  }),

  actions: {
    async retrieveAlbums(page, itemsPerPage, filter) {
      try {


        const headers = {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        };

        const requestOptions = {
          method: 'GET',
          headers: headers,
        };
  

        await fetch(`${APISettings.baseUrl}/api/albums/listAlbums?page=${page}&itemsPerPage=${itemsPerPage}&filter=${filter || ''}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          this.albums.length = 0;
          this.albums = [...data?.albums];
          this.totalPages = data?.totalPages
          this.numberOfRecords = data?.totalCount;
        });


      } catch (error) {
        console.error('Failed to retrieve albums:', error);
      }
    },
    async deleteAlbum(albumId, token) {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + token
        };
  
        const requestOptions = {
          method: 'DELETE',
          headers: headers
        };
  
        const response = await fetch(`${APISettings.baseUrl}/api/albums/deleteAlbum/${albumId}`, requestOptions);
        if (response.ok) {
          // Album deleted successfully
          // You may want to update the list of albums or any related state after deletion
          console.log('Post albumb successfully');
        } else {
          console.error('Failed to delete album:', response.statusText);
          // Handle deletion failure
        }
      } catch (error) {
        console.error('Failed to delete album:', error);
        // Handle deletion failure
      }
    },    
    async generateStaticAlbumPage(data, token) {
      try {

        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + token
        };

        const requestOptions = {
          method: 'POST',
          headers: headers,
          data: data
        };
  
        const response = await axios.post(APISettings.baseUrl + '/api/albums/generateStaticAlbumPage', data, requestOptions);
        if (response.data.success) {
          // File uploaded successfully
          this.updateError = null;
        } else {
          // File upload failed
          this.updateError = 'Failed to generate static page';
        }
      } catch (error) {
        console.error('Failed to generate static page:', error);
        this.updateError = 'Failed to generate static page';
      }
    },   
    async retrieveAlbum(albumId) {
      try {
        // Setting up the headers
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        };

        // Making the GET request to the server to retrieve the album details
        const response = await axios.get(`${APISettings.baseUrl}/api/albums/retrieveAlbum/${albumId}`, { headers });

        // Updating the selectedAlbum state with the retrieved album details
        this.selectedAlbum = response.data;
      } catch (error) {
        console.error('Failed to retrieve album:', error);
      }
    },
    async updateAlbum(albumId, updateData, token) {
      try {
        // Setting up headers with authorization
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        };

        // Preparing the request options, including the method, headers, and body
        const requestOptions = {
          headers: headers,
        };

        // Sending the PUT request to update the album
        const response = await axios.put(`${APISettings.baseUrl}/api/albums/updateAlbum/${albumId}`, updateData, requestOptions);

        if (response.data.success) {
          // Album updated successfully
          console.log('Album updated successfully');
        } else {
          // Handle the case where the update does not succeed
          console.error('Failed to update album');
        }
      } catch (error) {
        // Handle potential errors
        console.error('Error updating album:', error);
      }
    },    

  },
});
