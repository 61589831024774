<template>
    <div class="parent__div">
        <div class="left__div">
            <input @change="startSearch" type="text" v-model="searchText" placeholder="Enter text" />
        </div>
        <div class="right__div">
            <Icon icon="simple-line-icons:magnifier" width="24px" :inline="true" @click="startSearch"></Icon>
        </div>
    </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import { watch, ref } from 'vue';

export default {
    props: {
        filter: String,
    },
    setup(props, { emit }) {
        const searchText = ref('');

        // Watch for changes on the filter prop
        watch(
            () => props.filter,
            (newFilter) => {
                searchText.value = newFilter; // Update searchText with the new filter
                startSearch(); // Call startSearch method whenever the filter changes
            }
        );

        // Method to emit the search event
        function startSearch() {
            emit('startSearch', searchText.value);
        }

        return {
            searchText,
            startSearch,
        };
    },
    components: { Icon },
};
</script>

<style scoped>
.parent__div {
    display: flex;
    flex-wrap: wrap;
    max-width: 1484px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #FFF;
    border-radius: 8px;
    height: 56px;
}
.left__div {
    padding: 16px;
    flex: 99;
}
input {
    width: 100%;
    background-color: #EAEAEA;
    border-radius: 8px;
    padding: 4px 4px;
}
.right__div {
    padding: 16px;
    flex: 1;
}

.right__div > svg {
    cursor: pointer;
}


/* when the right column is displayed below the left column */
@media (max-width:1502px) {
    .parent__div {
        max-width: 1148px;
    }
}

</style>
