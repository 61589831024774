// post.js
import axios from 'axios';
import { defineStore } from 'pinia';
import { APISettings } from './config.js';

export const usePostStore = defineStore('post', {
  state: () => ({
    posts: [],
    numberOfRecords: 0,
    selectedPost: {},
    updateError: null,
    lastPage: null,
  }),

  actions: {
    // async retrievePosts(page, itemsPerPage, filter, albumId) {
    //   try {


    //     APISettings.headers.set('Content-Type', 'application/json');
    //     APISettings.headers.set('Access-Control-Allow-Origin', '*');

    //     const requestOptions = {
    //       method: 'GET',
    //       headers: APISettings.headers,
    //     };

    //     await fetch(`${APISettings.baseUrl}/api/posts/listposts?page=${page}&itemsPerPage=${itemsPerPage}&filter=${filter || ''}&albumId=${albumId || ''}`, requestOptions)
    //     .then(response => response.json())
    //     .then(data => {
    //       this.posts.length = 0;
    //       this.posts = [...data?.posts];
    //       this.numberOfRecords = data?.totalCount
    //     });


    //   } catch (error) {
    //     console.error('Failed to retrieve posts:', error);
    //   }
    // },

    async retrievePosts(page, itemsPerPage, filter, albumId, recaptchaToken) {
      try {
        APISettings.headers.set('Content-Type', 'application/json');
        APISettings.headers.set('Access-Control-Allow-Origin', '*');
        // Include the reCAPTCHA token in the request headers
        APISettings.headers.set('X-Recaptcha-Token', recaptchaToken);
    
        const requestOptions = {
          method: 'GET',
          headers: APISettings.headers,
        };
    
        await fetch(`${APISettings.baseUrl}/api/posts/listposts?page=${page}&itemsPerPage=${itemsPerPage}&filter=${filter || ''}&albumId=${albumId || ''}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          this.posts.length = 0;
          this.posts = [...data?.posts];
          this.numberOfRecords = data?.totalCount;
        });
      } catch (error) {
        console.error('Failed to retrieve posts:', error);
      }
    },
    

    async retrievePostById(postId) {
      try {
        const response = await fetch(`${APISettings.baseUrl}/api/posts/retrievePostById?postId=${postId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const postData = await response.json();
        this.selectedPost = postData; // Directly assign to selectedPost
      } catch (error) {
        console.error('Failed to retrieve post:', error);
        this.updateError = error.message; // Optionally update the error state
      }
    },

    setSelectedPost(whatPostData) {
      this.selectedPost = whatPostData;
    },

    async updatePost(data, token) {
      try {

        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + token
        };

        // const requestOptions = {
        //   method: 'POST',
        //   headers: headers,
        //   data: data
        // };
  
        const response = await axios.put(`${APISettings.baseUrl}/api/posts/updatePost`, data, { headers });

        if (response.data.success) {
          // File uploaded successfully
          this.updateError = null;
        } else {
          // File upload failed
          this.updateError = 'Failed to update post';
        }
      } catch (error) {
        console.error('Failed to update post:', error);
        this.updateError = 'Failed to update post';
      }
    },
    async deletePost(postId, token) {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + token
        };
  
        const requestOptions = {
          method: 'DELETE',
          headers: headers
        };
  
        const response = await fetch(`${APISettings.baseUrl}/api/posts/deletePost/${postId}`, requestOptions);
        if (response.ok) {
          // Post deleted successfully
          // You may want to update the list of posts or any related state after deletion
          console.log('Post deleted successfully');
        } else {
          console.error('Failed to delete post:', response.statusText);
          // Handle deletion failure
        }
      } catch (error) {
        console.error('Failed to delete post:', error);
        // Handle deletion failure
      }
    },
    async generateStaticPostPage(data, token) {
      try {

        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + token
        };

        const requestOptions = {
          method: 'POST',
          headers: headers,
          data: data
        };
  
        const response = await axios.post(APISettings.baseUrl + '/api/posts/generateStaticPostPage', data, requestOptions);
        if (response.data.success) {
          // File uploaded successfully
          this.updateError = null;
        } else {
          // File upload failed
          this.updateError = 'Failed to generate static post page';
        }
      } catch (error) {
        console.error('Failed to generate static post page:', error);
        this.updateError = 'Failed to generate static post page';
      }
    },               
  },

  mutations: {
    setSelectedPost(state, post) {
      state.selectedPost = post;
    }
  },
  

});
