<template>
  <div class="main__area">
    <div class="select-album-parent">
      <div class="local__toobar">
        <Icon icon="simple-line-icons:pencil" width="16px" :inline="true" v-if="canDeleteAlbum" @click="editAlbum()">
        </Icon>
  
        <Icon icon="simple-line-icons:trash" width="16px" :inline="true" 
          v-if="canDeleteAlbum" @click="showAlbumDeleteConfirmation()">
        </Icon>

        <Icon icon="material-symbols-light:html-sharp" width="16px" :inline="true" 
          v-if="canDeleteAlbum" @click="generateStaticAlbumPage()">
        </Icon>
        
        <Icon icon="simple-line-icons:share" width="16px" :inline="true" 
          @click="copyShareLink()">
        </Icon>

        <Icon icon="simple-line-icons:plus" width="16px" :inline="true" 
          v-if="canDeleteAlbum" @click="addPhotoToAlbum()">
        </Icon>

        <Icon icon="simple-line-icons:arrow-left-circle" width="16px" :inline="true"  @click="goBack()">
        </Icon>

      </div>

      <div class="upper-div">
        <h3>Album: {{ selectedAlbum.title }}</h3>
      </div>

      <div class="masonry">
        <div class="item" v-for="post in posts" :key="post.id">
          <a target="_newtab" @click="handlePostImageClicked(post)"><img :src="baseUrl + post.file" :alt="post.title"
              class="album__photo" :title="post?.title" /></a>
        </div>
      </div>

      <div class="upper-div">

<!--         <blockquote>
          <p>{{ selectedAlbum.description }}</p>
          <cite>{{ selectedAlbum.photographer?.name }}, on {{ formattedDate }}</cite>
        </blockquote> -->

        <div class="pill__container">
          <PillComponent v-for="tag in tags" :key="tag + selectedAlbum.id" :text="tag"></PillComponent>
        </div>

      </div>      

      <!-- Add the CommentsComponent here -->
      <CommentsComponent :albumId="route?.query?.albumId" :canDeleteOrEdit="canDeleteAlbum" :postDescription="selectedAlbum.description" :photographer="selectedAlbum.photographer?.name"
      :postDate="formattedDate" />

    </div>

    <ConfirmationDialog 
      v-if="isConfirmationVisible"
      :question="confirmationQuestion"
      @confirm="deleteAlbum()"
      @cancel="cancelDeleteAlbum()"
    />

  </div>
</template>

<script>
import { APISettings } from '../../store/config.js';
import { usePostStore } from '../../store/post';
import { useAlbumStore } from '../../store/album';
import { useRoute } from 'vue-router';
import { Icon } from '@iconify/vue';
import { useUserStore } from '../../store/user';
import ConfirmationDialog from '../components/ConfirmationDialog.vue';
import { useRouter } from 'vue-router';
import { onMounted, } from 'vue';
import PillComponent from '../components/PillComponent.vue';
import CommentsComponent from '../components/CommentsComponent.vue'; // Import CommentsComponent

export default {
  computed: {
    tags() {
      return this.selectedAlbum?.tags?.split(",").map(tag => tag.trim()) || [];
    },    
    formattedDate() {
      const date = new Date(this.selectedAlbum?.created);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },
    posts() {
      const postStore = usePostStore();
      return postStore.posts;
    },
    selectedAlbum() {
      const albumStore = useAlbumStore();
      return albumStore.selectedAlbum;
    },
    canDeleteAlbum() {
      const loggedInUser = this.userStore?.loggedInUser;
      const photographerId = this.selectedAlbum?.photographer?._id;
      const userId = loggedInUser?.id;

      return loggedInUser && userId == photographerId;
    },    
  },
  data() {
    return {
      showInfo: false,
      isConfirmationVisible: false,
      confirmationQuestion: "Are you sure?",      
    };
  },
  setup() {
    const baseUrl = APISettings.baseUrl;
    const albumStore = useAlbumStore();
    const route = useRoute();
    const postStore = usePostStore();
    const userStore = useUserStore();
    const router = useRouter();

    const fetchAlbumIfNeeded = async () => {
      // Assuming postId is a parameter in the URL
      const albumId = route?.query?.albumId;
      
      // Check if selectedAlbum is not available but albumId is
      if ((!albumStore?.selectedAlbum?.description) && albumId) {
        await albumStore.retrieveAlbum(albumId);
      }
    };

    onMounted(() => {
      fetchAlbumIfNeeded();
    }); 

    return {
      baseUrl,
      albumStore,
      route,
      postStore,
      userStore,
      router,
    };
  },
  methods: {
    async editAlbum() {
      this.router.push("/editAlbum");
    },    
    async handlePostImageClicked(post) {
      this.postStore.selectedPost = post;
      this.router.push({path: "viewPost", query: {postId: post?._id}});
    },      
    showAlbumDeleteConfirmation() {
      this.isConfirmationVisible = true;
      this.confirmationQuestion = "Are you sure you want to delete this album?";
    },    
    goBack() {
      window.history.back();
    },
    bigImageUrl(file) {
      return this.baseUrl + file.replaceAll('resized_', '');
    },
    async deleteAlbum() {
      await this.albumStore.deleteAlbum(this.selectedAlbum?._id, this.userStore?.loggedInUser?.token);
      this.isConfirmationVisible = false;
      this.router.push("/mainPage");
    },
    cancelDeleteAlbum() {
      this.isConfirmationVisible = false;
    },  
    async generateStaticAlbumPage() {
      const postFormData = new FormData();
      postFormData.append("albumId", this.selectedAlbum?._id); 
      await this.albumStore.generateStaticAlbumPage(postFormData, this.userStore?.loggedInUser?.token)
        .then(() => {
          // This will open the album in a new tab after the post generation is successful
          window.open(`/public/album-${this.selectedAlbum?._id}.html`, '_blank');
        })
        .catch(error => {
          // Handle any errors that occur during the post generation
          console.error("Error generating static post:", error);
        });
    },
    async copyShareLink() {
      const postFormData = new FormData();
      postFormData.append("albumId", this.selectedAlbum?._id);

      try {
        await this.albumStore.generateStaticAlbumPage(postFormData, this.userStore?.loggedInUser?.token);
        await this.copyText(`https://memoirlens.com/public/album-${this.selectedAlbum?._id}.html`);
      } catch (error) {
        // Handle any errors that occur during the post generation
        console.error("Error generating static post:", error);
      }
    },
    async copyText(textToCopy) {
      try {
        // Check if the Clipboard API is available
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(textToCopy);
          alert('Share link copied to clipboard!');
        } else {
          // Fallback for older browsers
          alert('Share link copy failed!');
        }
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },    
    async addPhotoToAlbum() {
      this.router.push("/addPost");
    }
  },
  components: {
    Icon,
    ConfirmationDialog,
    PillComponent,
    CommentsComponent // Register CommentsComponent
  },
  async mounted() {
    const filter = "";
    window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute('6Ld_uZspAAAAAOwcp6W8xAwXQ_vZJzuYMLGc5gnh', { action: 'retrieve_posts' });
        await this.postStore.retrievePosts(1, 20, filter, this.route?.query?.albumId, token);
      });
  }
};
</script>

<style scoped>
.local__toobar {
  display: flex;
  float: right;
  margin-left: auto;
  padding: 8px;
}

.local__toobar  > svg {
  margin: 4px;
  cursor: pointer;
}

.album__photo {
  cursor: pointer;
}

.main__area {
  background-color: #CBD18F;
  padding: 8px;
  min-height: 100vh;
}

.upper-div {
  text-align: left;
  padding: 8px;
}

.select-album-parent {
  display: flex;
  background-color: #FFF;
  border-radius: 8px;
  flex-wrap: wrap;
  max-width: 1485px;
  margin: auto;
  flex-direction: column;
}

.masonry {
  column-count: 3;
  /* Define o número de colunas desejado */
  column-gap: 8px;
  /* Define o espaçamento entre as colunas */
  padding: 8px;
  transition: border 0.2s, padding 0.2s;
  /* Add a transition for smoother effect */
}

.item {
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
}

img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

h3 {
  margin: 8px 0;
}

p {
  margin: 0;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

cite {
  margin-top: 12px;
  display: block;
  font-style: italic;
  text-align: right;

  &:before {
    content: "- ";
  }
}

.pill__container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;
}

.pill-component {
  cursor: default; 
}

@media (max-width:1280px) {
  .masonry {
    column-count: 2;
  }
}

@media (max-width:602px) {
  .masonry {
    column-count: 1;
  }
}
</style>
