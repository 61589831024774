<template>
  <div class="parent__div">
    <form @submit.prevent="handleSubmit">
      <div>
        <label for="title">Title:</label>
        <input type="text" id="title" v-model="form.title" required>
      </div>
      <div>
        <label for="description">Description:</label>
        <textarea id="description" v-model="form.description" maxlength="153" required></textarea>
      </div>
      <div v-if="!postData._id">
        <label for="file">File:</label>
        <input type="file" id="file" @change="handleFileChange" required>
      </div>
      <div>
        <label for="tags">Tags:</label>
        <input type="text" id="tags" v-model="form.tags" required>
      </div>
      <button type="submit">Submit</button>
      <button type="button" @click="$emit('onCancelForm', form)">Cancel</button>
    </form>
    <p v-if="store.uploadError" class="error">{{ store.uploadError }}</p>
  </div>
</template>
  
<script>
import { useUploadStore } from '../../store/upload';
import { usePostStore } from '../../store/post';


export default {
  setup(props) {
    const postStore = usePostStore();
    const postData = props?.inserting ? {} : (postStore.selectedPost || {});

    return {
        postStore,
        postData,
    };
  },  
  props: {
    loggedInUser: {
      type: Object,
      required: true
    },
    album: {
      type: Object,
      required: false
    },    
    inserting: {
      type: Boolean,
      required: false
    },    
  },
  data() {
    return {
      form: {
        _id: this.postData._id || null,
        title: this.postData?.title || '',
        description: this.postData?.description || '',
        file: this.postData?.file || null,
        filename: this.postData?.filename || '',
        photographer: this.album?.photographer || null,
        albumId: this.album?._id,
        tags: this.postData?.tags || '',
      },
    };
  },
  computed: {
    store() {
      return useUploadStore();
    },
  },
  methods: {
    handleSubmit() {
      const formData = new FormData();

      if (this.inserting) {
        formData.append('albumId', this.form.albumId);
      }

      formData.append('title', this.form.title);
      formData.append('description', this.form.description);
      
      formData.append('tags', this.form.tags);

      if (!this.postData._id) {
        formData.append('file', this.form.file);
        formData.append('photographer', this.loggedInUser?.id);
      } else {
        formData.append('_id', this.postData._id);
      }

      this.$emit('handleSubmit', formData);
    },
    handleFileChange(event) {
      this.form.file = event.target.files[0];
      this.form.filename = event.target.files[0]?.filename;
    }
  }
};
</script>
  
<style scoped>

button {
  margin-left: 8px;
}

.error {
  color: red;
}

.parent__div {
  background-color: #FFF;
  border-radius: 8px;
  min-width: 302px;
  


}


.parent__div>form>div {
  display: flex;
  flex-wrap: wrap;

}


.parent__div>form>div>label {
  padding: 8px;
  width: 110px;
  text-align: left;
}



.parent__div>form>div>input {
  padding: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 350px;
}

.parent__div>form>div>textarea {
  padding: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 350px;
}

@media (max-width:860px) {
  .parent__div {
    min-width: unset;
    max-width: 100%;
    margin: 8px 0px 0px 8px;
    margin: 8px 0px 0px 8px;

  }

  .parent__div>form>div>input {
    width: 100%;
  }

  .parent__div>form>div>textarea {
    width: 100%;
  }


}


</style>
  