import LoginPage from './components/LoginPage.vue';
import LoginForm from './components/LoginForm.vue';
import ListAlbums from './components/ListAlbums.vue';
import ListPosts from './components/ListPosts.vue';
import MainPage from './components/MainPage.vue';
import AddPost from './components/AddPost.vue';
import EditPost from './components/EditPost.vue';
import AddAlbum from './components/AddAlbum.vue';
import EditAlbum from './components/EditAlbum.vue';
import ViewAlbum from './components/ViewAlbum.vue';
import ViewPost from './components/ViewPost.vue';
import { useUserStore } from '../store/user';
import { createRouter, createWebHistory } from 'vue-router'
import AboutMe from './components/AboutMe.vue';
import NotFoundPage from './components/NotFoundPage.vue';

/* eslint-disable */ 
const router = createRouter({
    history: createWebHistory(),
    routes : [
      {
        path: '/',
        component: MainPage,
        meta: { requiresAuth: false },
      },     
      {
        path: '/about',
        component: AboutMe,
        name: "AboutMe",
        meta: { requiresAuth: false },
      },       
      {
        path: '/login',
        component: LoginPage,
        name: "LoginPagee",
        meta: { requiresAuth: false },
      },
      {
        path: '/loginForm',
        component: LoginForm,
        name: "LoginForm",
        meta: { requiresAuth: false },
      },
      {
        path: '/albums',
        component: ListAlbums,
        name: "ListAlbums",
        meta: { requiresAuth: true },
      },
      {
        path: '/posts',
        component: ListPosts,
        name: "ListPosts",
        meta: { requiresAuth: true },
      },
      {
        path: '/addPost',
        component: AddPost,
        name: "AddPost",
        meta: { requiresAuth: true },
      },   
      {
        path: '/editPost',
        component: EditPost,
        name: "EditPost",
        meta: { requiresAuth: true },
      },        
      {
        path: '/addAlbum',
        component: AddAlbum,
        name: "AddAlbum",
        meta: { requiresAuth: true },
      },   
      {
        path: '/editAlbum',
        component: EditAlbum,
        name: "EditAlbum",
        meta: { requiresAuth: true },
      },              
      {
        path: '/mainPage',
        name: "MainPage",
        component: MainPage,
        meta: { requiresAuth: false },
      },    
      {
        path: '/viewAlbum',
        name: "ViewAlbum",
        component: ViewAlbum,
        meta: { requiresAuth: false },
      },      
      {
        path: '/viewPost',
        name: "ViewAPost",
        component: ViewPost,
        meta: { requiresAuth: false },
      },   
      {
        path: '/:catchAll(.*)*', // This line catches all routes that aren't matched by earlier rules
        component: NotFoundPage
      },             
  ],
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
})


router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  if (to.meta.requiresAuth && !userStore.loggedInUser) {
    next('/login');
  } else {
    next();
  }
});

export default router;
