<template>
    <div class="post__area">

        <div class="inner__post_area">
            <div class="post__form">

                <PostForm :loggedInUser="userStore.loggedInUser" :album="selectedAlbum" @handleSubmit="handleSubmit" inserting  @onCancelForm="cancelAddPost"></PostForm>

            </div>

            <div class="post__text">
                <h3>Add photo to album</h3>

                <p>Maximum size per image: 20MB. Enter as much information as possible. The description field will be displayed when the user opens the album.</p>

                <p>All the technical information about the photos (like ISO, aperture and shutter speed) will be automatically detected.</p>

                <p>Add tags to make the photos easier to find.</p>



            </div>

        </div>



    </div>
</template>
  
<script>
import PostForm from './PostForm.vue'
import { useUploadStore } from '../../store/upload';
import { useRouter } from 'vue-router';
import { useUserStore } from '../../store/user';
import { useAlbumStore } from '../../store/album';

export default {
    setup() {
    const router = useRouter();

    return {
        router,
    };
  },
    data() {
        return {

        };
    },
    components: {
        PostForm
    },
    computed: {
        store() {
            return useUploadStore();
        },
        userStore() {
            return useUserStore();
        },
        selectedAlbum() {
            const albumStore = useAlbumStore();
            return albumStore.selectedAlbum;
        },     
    },
    mounted() {

    },
    methods: {
        cancelAddPost() {
            window.history.back();
        },
        async handleSubmit(formData) {
            // Call store method to send data
            //await this.store.uploadFile(formData, this.userStore?.loggedInUser?.token);

            const postFormData = new FormData();
            postFormData.append("isLastFile", true); 
            postFormData.append("isAlbum", false);


            for (const [key, value] of formData.entries()) { // Iterate over formData entries
                if (key !== 'files' && key !== 'file') { // Skip 'files' and 'file' keys
                    postFormData.append(key, value); // Append other entries to albumFormData
                }
            }

            const file = formData.get('file');
            if (file) {
                await this.store.chunkUpload(file, postFormData, this.userStore?.loggedInUser?.token);
                window.history.back();
            }


        }
    }
};
</script>
<style scoped>
.post__area {

    background-color: #CBD18F;
    padding: 8px;
    height: 100dvw;

}

.inner__post_area {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
}

.post__text {
    background-color: #FFF;
    border-radius: 8px;
    text-align: left;
    max-width: 950px;
    padding: 0px 0px 8px 8px;
    margin: 0px 0px 0px 8px;
}

.post__text>p {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

div.post__text > h3 {
    padding: 0px 0px 0px 0px;
}


.post__form {
    background-color: #FFF;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;

}

.post__form>form>div {
    display: flex;
    flex-wrap: wrap;


}

.post__form>form>div>label {
    padding: 8px;
    width: 110px;
    text-align: left;
}

.post__form>form>div.photographer__input>label {
    padding: 8px;
    width: 110px;
    text-align: left;
}

.post__form>form>div>input {
    padding: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 350px;
}

.post__form>form>div.photographer__input>input {
    width: unset;
    padding: 8px;
    margin-bottom: 8px;
    margin-right: 8px;

}

.post__form>form>div>textarea {
    padding: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 350px;
}


@media (max-width:860px) {
    .post__area {

        background-color: #CBD18F;
        padding: 8px;
        height: fit-content;
    }

    .post__form {
        padding: 8px;
        margin-bottom: 8px;
    }


    .post__form>form>div {
        justify-items: left;
        padding: 8px;
    }

    .post__form>form>div>input {
        padding: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: unset;
        flex: 100%;
    }

    .post__form>form>div.photographer__input>input {
        padding: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: unset;
        flex: none;

    }

    .post__form>form>div>textarea {
        padding: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: unset;
        flex: 100%;
    }

    .post__form>form>div>label {
        padding: 8px;
        margin-right: 8px;
        flex: 100%;

    }

    .post__text {
        padding: 8px 0px 0px 8px;
        margin: 8px 0px 0px 8px;
    }

}
</style>
  