<template>
  <div class="post-parent-layout">
    
    <div class="post-left-div">
      <a  
        target="_newtab" 
        @click="handlePostImageClicked()">
          <img :src="baseUrl + data.file" :alt="data.title" class="post__photo" />
      </a>

      <div class="action__icons">
        <Icon icon="simple-line-icons:heart" width="16px" :inline="true" :class="{ 'icon-disabled': !isLoggedIn }">
        </Icon>

        <Icon icon="simple-line-icons:like" width="16px" :inline="true" :class="{ 'icon-disabled': !isLoggedIn }">
        </Icon>

        <Icon icon="simple-line-icons:bag" width="16px" :inline="true" :class="{ 'icon-disabled': !isLoggedIn }">
        </Icon>

        <Icon icon="simple-line-icons:tag" width="16px" :inline="true" :class="{ 'icon-disabled': !isLoggedIn }">
        </Icon>

        <Icon icon="simple-line-icons:cloud-download" width="16px" :inline="true" :class="{ 'icon-disabled': !isLoggedIn }">
        </Icon>

        <Icon icon="simple-line-icons:pencil" width="16px" :inline="true" v-if="canDeleteOrEdit" @click="editPost()">
        </Icon>

        <Icon icon="simple-line-icons:trash" width="16px" :inline="true" v-if="canDeleteOrEdit" @click="showPostDeleteConfirmation()">
        </Icon>

        <Icon icon="simple-line-icons:speech" width="16px" :inline="true" :class="{ 'icon-disabled': !isLoggedIn }">
        </Icon>

                     
      </div>



    </div>
    <div class="post-right-div">
      <h3>{{ data.title }}</h3>
      <p @click="showPhotographerInfo">Photographer: <span class="photographer__info">{{ data.photographer?.name }}</span>
      </p>
      <p>Date: {{ formattedDate }}</p>
      <p>Dimensions: {{ data.dimensions }}</p>
      <p>ISO Speed: {{ data.iso }}</p>
      <p>Exposure Time: {{ data.exposure }}</p>
      <p>F-Stop: {{ data.f_stop }}</p>
      <p>Description: {{ data.description }}</p>

      <div class="pill__container">
        <PillComponent v-for="tag in tags" :key="tag + data.id" :text="tag" @clicked="emitPillClicked"></PillComponent>
      </div>
    </div>


      <ConfirmationDialog 
      v-if="isConfirmationVisible"
      :question="confirmationQuestion"
      @confirm="deletePost()"
      @cancel="cancelDeletePost()"
    />

  </div>
</template>
  
<script>
import { APISettings } from '../../store/config.js';
import { Icon } from '@iconify/vue'
import PillComponent from '../components/PillComponent.vue'
import { useUserStore } from '../../store/user';
import { usePostStore } from '../../store/post';
import { useRouter } from 'vue-router';
import ConfirmationDialog from '../components/ConfirmationDialog.vue';



export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    }    
  },
  data() {
    return {
      isConfirmationVisible: false,
      confirmationQuestion: "Are you sure?",
    };
  },
  setup() {
    const baseUrl = APISettings.baseUrl;
    const userStore = useUserStore();
    const postStore = usePostStore();
    const router = useRouter();

    return {
      baseUrl,
      userStore,
      postStore,
      router,

    };
  },
  computed: {
    tags() {
      return this.data?.tags?.split(",").map(tag => tag.trim()) || [];
    },
    formattedDate() {
      const date = new Date(this.data?.created);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },
    canDeleteOrEdit() {
      const loggedInUser = this.userStore?.loggedInUser;
      const photographerId = this.data?.photographer?._id;
      const userId = loggedInUser?.id;

      return loggedInUser && userId == photographerId;
    },  
    isLoggedIn() {
      return this.userStore?.loggedInUser;
    },     
  },
  methods: {
    emitPillClicked(text) {
      this.$emit('pillClicked', text);
      console.log("pillClicked 1", text);
    },
    async handlePostImageClicked() {
      this.postStore.selectedPost = this.data;
      this.postStore.lastPage = this.page;
      this.router.push({path: "viewPost", query: {postId: this.data?._id}});

    },    
    showPostDeleteConfirmation() {
      this.isConfirmationVisible = true;
      this.confirmationQuestion = "Are you sure you want to delete this post?";
    },
    async editPost() {
      await this.postStore.setSelectedPost(this.data);
      this.postStore.lastPage = this.page;
      this.router.push("/editPost");
    },
    async deletePost() {
      await this.postStore.deletePost(this.data?._id, this.userStore?.loggedInUser?.token);
      this.isConfirmationVisible = false;
    },
    cancelDeletePost() {
      this.isConfirmationVisible = false;
    },
    showPhotographerInfo() {
      this.router.push("/about");
    },
    bigImageUrl(file) {
      return  this.baseUrl + file.replaceAll('resized_', '');
    },
  },
  components: { Icon, PillComponent, ConfirmationDialog}
};
</script>
   
<style scoped>
.icon-disabled {
  opacity: 0.5; 
  pointer-events: none; 
  cursor: default; 
}


.post-parent-layout {
  display: flex;
  background-color: #FFF;
  border-radius: 8px;
  flex-wrap: wrap;
  padding: 8px;
  margin-top: 4px;

}


.post-left-div {
  text-align: left;
  padding: 8px;
  max-width: 502px;
}

.post-left-div>svg {
  cursor: pointer;
}

.action__icons {
  padding: 8px 8px 0px 0px;
}

.action__icons > svg {
  padding-left: 8px;
  cursor: pointer;
}

.post__photo {
  width: 100%;
  max-width: 602px;
  height: auto;
  display: flex;
  cursor: pointer;
  border-radius: 8px;
}

.post-right-div {
  text-align: left;
  padding: 0px 8px 8px 0px;
  font-size: 0.8em;
  max-width: 302px;
  width: fit-content;
  /* min-width: 302px; */
}

.photographer__info {
  color: #E3B448;
  text-decoration: none;
  cursor: pointer;

}

.post-right-div>p {
  margin: 8px;

}

h3 {
  padding: 0px 0px 0px 8px;
}

.pill__container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;
}

@media (max-width: 540px) {
  .post-left-div {
    padding: 0px;
  }

  .post-right-div {
    padding: 0px;
    max-width: 100%;
    min-width: 100%;
  }  

  h3 {
    padding: 0px;
  }

  .post-right-div > p {
    margin: 8px 0px 8px 0px;
  }

  

}

@media (min-width: 1080px) and (max-width: 1501px) {

  .post-right-div {
    padding: 0px;
  }  

  .post__photo {
    max-width: 502px;
  }

  .post-parent-layout {
    display: flex;
    flex-direction: column;
  }

  .post-left-div {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    max-width: 502px;
  }  


  .post-parent-layout {
    padding: 4px;

  }


  .post-left-div {
    padding: 4px;
  }


}

@media  (min-width:1150px) and (max-width: 1280px) {
  .post-parent-layout {
    margin-right: 8px;
  }
}


@media (min-width:603px) {
  .post-left-div {
    margin-top: 8px;
  }  

}

@media (max-width: 859px) {
  .post-left-div {
    margin: auto;
  }

  .post-right-div {
    /* min-width: 302px; */
  }

}



</style>
  