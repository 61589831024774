/* eslint-disable */
import { defineStore } from 'pinia';
import { APISettings } from './config.js';
import axios from 'axios';

export const useUserStore = defineStore('user', {
  state: () => ({
    loggedInUser: null,
    persistError: null,
  }),

  actions: {
    async validateLogin(credentials) {
      try {

        this.loggedInUser = null;

        APISettings.headers.set('Content-Type', 'application/json');
        APISettings.headers.set('Access-Control-Allow-Origin', '*');

        const requestOptions = {
          method: 'POST',
          headers: APISettings.headers,
          body: JSON.stringify(credentials)
        };

        await fetch(APISettings.baseUrl + '/api/login/validateLogin', requestOptions)
            .then(response => response.json())
            .then(data => {
              if (!data?.error) {
                this.loggedInUser = data;
              }
            });



            
        if (!this.loggedInUser) {
          console.error('Login failed:');
          //throw new Error('Invalid login credentials');
          return false;
        } 

        return true;

        
      } catch (error) {
        console.error('Login failed:', error);
        //throw error;
        return false;
      }
    },
    async sendData(data, token) {
      try {

        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + token || ''
        };

        const requestOptions = {
          method: 'POST',
          headers: headers,
          data: data
        };
  
        const response = await axios.post(APISettings.baseUrl + '/api/login/persistUser', data, requestOptions);
        if (response.status === 200 || response.status === 201) {
          // User persisted successfully
          this.persistError = null;
          return response.status;
        } else {
          // User persist failed
          this.persistError = 'Failed to persist user';
          return response.status;
        }
      } catch (error) {
        console.error('Failed to persist:', error);
        this.persistError = 'Failed to persist';
        return response.status;
      } 
    },    
    logout() {
      this.loggedInUser = null;
    }

  },
});
