<template>
    <div class="error__area">

        <div class="inner__error_area">
            <div class="site__text__wrapper">
                <div class="local__toobar">
                    <Icon icon="simple-line-icons:arrow-left-circle" width="16px" :inline="true" @click="goBack()">
                    </Icon>
                </div>
                <div class="site__text">
                    <img :src="currentImage" class="image" />

                    <h1>404?!</h1>

                    <h3>Oops! Looks like you're lost.</h3>

                    <div class="text">
                        <p>Whoa, where are we? This isn't where I parked my website. Looks like you've stumbled onto a
                            page that doesn't exist.</p>
                        <p>But no worries! Just click on the back arrow above, or if you're feeling adventurous, you can
                            explore the rest of our site. There's plenty to see!</p>
                        <p>And hey, since you're here, why not enjoy a random cool picture? That's right, refresh the
                            page and get a new one each time. It's like a tiny surprise gift from us to you.</p>
                    </div>
                </div>
            </div>

            <div class="error__footer">
                <img class="site__logo" src="../assets/android-chrome-192x192.png" />
                <span>Memoirlens</span>
                <span>Every Shot Holds a Memory</span>
            </div>

        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { Icon } from '@iconify/vue';

export default {
    name: 'NotFound',
    setup() {
        const router = useRouter();
        // Dynamically assign images based on computed properties
        const imageIndex = ref(1); // Initialize with any valid index
        const currentImage = computed(() => {
            return require(`@/assets/404_image_${imageIndex.value}.jpg`); // Adjust path if needed, using webpack alias @ for src
        });

        // Method to update the image index randomly
        function updateImage() {
            imageIndex.value = Math.floor(Math.random() * 13) + 1;
        }

        // Initial random image setup
        updateImage();

        return {
            currentImage,
            updateImage,
            router,
        };
    },
    methods: {
        goBack() {
            window.history.back();
        }
    },
    components: { Icon }
};
</script>


<style scoped>
.local__toobar {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    padding: 8px;
}

.local__toobar>svg {
    margin: 4px;
    cursor: pointer;
}

.image {
    display: flex;
    width: 100%;
    height: auto;
    max-width: 400px;
    max-height: 400px;
    float: left;
    margin: 5px;
}

.error__area {
    background-color: #CBD18F;
    padding: 4px;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
}

.inner__error_area {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 1484px;
    width: 100%;
    margin: auto;
    justify-content: center;
    margin-top: 4px;
    height: auto;
}

.site__text__wrapper {
    background-color: #FFF;
    border-radius: 8px;
    padding: 8px;
}

.site__text {
    padding: 8px;
    text-align: left;
    height: auto;
}

.error__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 8px;
}

.error__footer>span {
    color: #3A6B35;
}

.error__footer>span:nth-child(2) {
    font-size: 1.2em;
}

.error__footer>span:nth-child(3) {
    font-size: 0.8em;
}

.site__logo {
    width: 100%;
    height: auto;
    max-width: 64px;
    margin: 8px;
}

@media (max-width: 790px) {
   .image {
        margin: auto;
        float: none;
   }

   h3, h1 {
    text-align: center;
   }
  
}

</style>