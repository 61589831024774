<template>
    <div class="post__area">

        <div class="inner__post_area">
            <div class="post__form">

                <PostForm :postaData="postStore.selectedPost" :loggedInUser="userStore.loggedInUser" @handleSubmit="handleSubmit" @onCancelForm="cancelEditPost"></PostForm>

            </div>

            <div class="site__text">
                <h3>Welcome to the User Profile Management</h3>

                <p>At MemoirLens, we value your individuality and want to ensure that your experience with us is
                    personalized. This page allows you to manage your user profile and keep your information up to date.</p>

                <p>If you are a returning user, simply log in using your existing credentials to access and update your
                    profile. You can modify details such as your name, email address, and preferences to ensure that we
                    tailor our services to your needs.</p>

                <p>New to MemoirLens? No problem! Creating a user account is quick and easy. Fill in the required fields,
                    set your preferences, and join our community of photography enthusiasts. By becoming a member, you gain
                    access to exclusive offers, personalized recommendations, and the ability to save your favorite
                    photographs for future reference.</p>

                <p>We prioritize the security and confidentiality of your personal information. Rest assured that your data
                    is encrypted and protected through robust security measures, so you can update your profile with peace
                    of mind.</p>

                <p>Take control of your user profile today and let us enhance your MemoirLens experience. Your moments, your
                    memories, your profile!</p>

            </div>

        </div>



    </div>
</template>
  
<script>
import PostForm from './PostForm.vue'
import { useUploadStore } from '../../store/upload';
import { useRouter } from 'vue-router';
import { useUserStore } from '../../store/user';
import { usePostStore } from '../../store/post';

export default {
    setup() {
        const router = useRouter();
        const postStore = usePostStore();

        return {
            router,
            postStore,
        };
    },
    data() {
        return {

        };
    },
    components: {
        PostForm
    },
    computed: {
        store() {
            return useUploadStore();
        },
        userStore() {
            return useUserStore();
        },
    },
    mounted() {

    },
    methods: {
        async goBack() {
            if (window.history.length > 1) {
                window.history.back();
            } else {
                this.$router.push('/mainPage');
            } 
        },
        async cancelEditPost() {
            await this.goBack();           
        },
        async handleSubmit(formData) {
            // Call store method to send data
            await this.postStore.updatePost(formData, this.userStore?.loggedInUser?.token);
            await this.goBack();


        }
    }
};
</script>
<style scoped>
.post__area {

    background-color: #CBD18F;
    padding: 8px;
    height: 100dvw;

}

.inner__post_area {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
}

.site__text {
    background-color: #FFF;
    border-radius: 8px;
    padding: 0px 0px 0px 8px;
    text-align: left;
    max-width: 950px;
    margin: 0px 0px 0px 8px;
    display: flex;
    flex-wrap: wrap;
}

.site__text>p {
    margin-bottom: 0px;
    padding-bottom: 0px;
}


.post__form {
    background-color: #FFF;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;

}

.post__form>form>div {
    display: flex;
    flex-wrap: wrap;


}

.post__form>form>div>label {
    padding: 8px;
    width: 110px;
    text-align: left;
}

.post__form>form>div.photographer__input>label {
    padding: 8px;
    width: 110px;
    text-align: left;
}

.post__form>form>div>input {
    padding: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 350px;
}

.post__form>form>div.photographer__input>input {
    width: unset;
    padding: 8px;
    margin-bottom: 8px;
    margin-right: 8px;

}

.post__form>form>div>textarea {
    padding: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 350px;
}


@media (max-width:860px) {
    .post__area {

        background-color: #CBD18F;
        padding: 8px;
        height: fit-content;
    }

    .post__form {
        padding: 8px;
        margin-bottom: 8px;
    }


    .post__form>form>div {
        justify-items: left;
        padding: 8px;
    }

    .post__form>form>div>input {
        padding: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: unset;
        flex: 100%;
    }

    .post__form>form>div.photographer__input>input {
        padding: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: unset;
        flex: none;

    }

    .post__form>form>div>textarea {
        padding: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: unset;
        flex: 100%;
    }

    .post__form>form>div>label {
        padding: 8px;
        margin-right: 8px;
        flex: 100%;

    }

    .site__text {
        padding: 8px 0px 0px 8px;
        margin: 8px 0px 0px 8px;
    }

}
</style>
  