<template>
  <div class="single__div" >
    <div class="album__header">
      <div class="album__info">
        <h3><b>Album Title:</b> {{ data.title }}</h3>
        <p @click="showPhotographerInfo">Photographer: <span class="photographer__info">{{ data.photographer?.name }}</span></p>
      </div>
      <div class="album__calendar">
        <Icon icon="simple-line-icons:calendar" width="24px" :inline="true">
        </Icon>
      </div>
    </div>

    <img @click="emitAlbumClicked" class="album__photo" :src="baseUrl + data.file" :alt="data.title" />
  </div>
</template>
  
<script>
import { APISettings } from '../../store/config.js';
import { Icon } from '@iconify/vue';
import { useRouter } from 'vue-router';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },  
  setup() {
    const baseUrl = APISettings.baseUrl;
    const router = useRouter();

    return {
      baseUrl,
      router,

    };
  },
  methods: {
    emitAlbumClicked() {
      this.$emit('albumClicked', this.data);

    },
    showPhotographerInfo() {
      this.router.push("/about");
    },    
  },
  components: { Icon,  }
};
</script>
  
<style scoped>
.single__div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #FFF;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 0px 16px 16px 16px;
  text-align: left;
  font-size: 0.8em;
}

.album__photo {
  width: 100%;
  max-width: 602px;
  height: auto;
  border-radius: 8px;
  cursor: pointer;

}

.album__header {
  display: flex;
  flex-wrap: wrap;

}

.album__info {
  flex: 99;
}

.album__info > h3 {
  padding: 0px 0px 0px 0px;
}


.album__calendar {
  align-self: center;
  flex: 1;
}

.photographer__info {
  color: #E3B448;
  /* Change the color to your preferred choice */
  text-decoration: none;
  cursor: pointer;

}


/* @media (min-width: 1200px) and (max-width: 1501px) {
  .album__photo {
    max-width: 502px;
  }
} */

@media (max-width:860px) { 

  .single__div {
    padding: 0px 8px 8px 8px;
  }
}

@media  (max-width: 1502px) {
  .single__div {
    padding: 0px 8px 8px 8px;
  }
}


@media (max-width:1080px) {

  .single__div {
    padding: 0px 8px 8px 8px;
  }

  .single__div > img {
    margin: auto;
  }

}
</style>
  