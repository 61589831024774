<template>
    <div class="album__area">

        <div class="inner__album_area">
            <div class="album__form">

                <AlbumForm :loggedInUser="userStore.loggedInUser" @handleSubmit="handleSubmit" @onCancelForm="cancelAddAlbum"></AlbumForm>

                <div v-if="uploadStatusMessage" class="upload__status">{{ uploadStatusMessage }}</div>

            </div>

            <div class="album__text">
                <h3>Edit album</h3>

                <p>Maximum size per image: 20MB. Enter as much information as possible. The description field will be displayed when the user opens the album.</p>

                <p>All the technical information about the photos (like ISO, aperture and shutter speed) will be automatically detected.</p>

                <p>Add tags to make the albums easier to find.</p>

                <p>For the cover photo, choose the image from the collection that stands out the most.</p>

                <p>It is okay to add the photo that was chosen as the cover photo again in the "files" field.</p>

            </div>

        </div>



    </div>
</template>
  
<script>
import AlbumForm from './AlbumForm.vue'
import { useAlbumStore } from '../../store/album';
import { useRouter } from 'vue-router';
import { useUserStore } from '../../store/user';
import { useUploadStore } from '../../store/upload';

export default {
    setup() {
    const router = useRouter();

    return {
        router,
    };
  },
    data() {
        return {
            uploadStatusMessage: null,
        };
    },
    components: {
        AlbumForm
    },
    computed: {
        albumStore() {
            return useAlbumStore();
        },
        userStore() {
            return useUserStore();
        },
        uploadStore() {
            return useUploadStore();
        }
    },
    mounted() {

    },
    methods: {
        async cancelAddAlbum() {
            this.router.push("/mainPage");
        },
        async handleSubmit(submitData) {

            const albumFormData = new FormData();
            let albumId = null;

            for (const [key, value] of submitData?.formData.entries()) {
                if (key !== 'files' && key !== 'file' && key !== '_id') {
                    albumFormData.append(key, value);
                }

                if (key == '_id') {
                    albumId = value;
                }                
            }

            // Call store method to update the album
            await this.albumStore.updateAlbum(albumId, albumFormData, this.userStore?.loggedInUser?.token);

            this.router.push("/mainPage");


        }
    }
};
</script>
<style scoped>
.album__area {

    background-color: #CBD18F;
    padding: 8px;
    height: 100dvw;

}

.inner__album_area {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
}

.album__text {
    background-color: #FFF;
    border-radius: 8px;
    text-align: left;
    max-width: 950px;
    padding: 0px 0px 8px 8px;
    margin: 0px 0px 0px 8px;
}

.album__text>p {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

div.album__text > h3 {
    padding: 0px 0px 0px 0px;
}

.album__form {
    background-color: #FFF;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

}

.album__form>form>div {
    display: flex;
    flex-wrap: wrap;


}

.album__form>form>div>label {
    padding: 8px;
    width: 110px;
    text-align: left;
}

.album__form>form>div.photographer__input>label {
    padding: 8px;
    width: 110px;
    text-align: left;
}

.album__form>form>div>input {
    padding: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 350px;
}

.album__form>form>div.photographer__input>input {
    width: unset;
    padding: 8px;
    margin-bottom: 8px;
    margin-right: 8px;

}

.album__form>form>div>textarea {
    padding: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 350px;
}

.upload__status {
    margin-top: 8px;
    font-size: 1.2em;
    /* border: 1px solid #3A6B35; */
}

@media (max-width:860px) {
    .album__area {

        background-color: #CBD18F;
        padding: 8px;
        height: fit-content;
    }

    .album__form {
        padding: 8px;
        margin-bottom: 8px;
    }


    .album__form>form>div {
        justify-items: left;
        padding: 8px;
    }

    .album__form>form>div>input {
        padding: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: unset;
        flex: 100%;
    }

    .album__form>form>div.photographer__input>input {
        padding: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: unset;
        flex: none;

    }

    .album__form>form>div>textarea {
        padding: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: unset;
        flex: 100%;
    }

    .album__form>form>div>label {
        padding: 8px;
        margin-right: 8px;
        flex: 100%;

    }

    .album__text {
        padding: 8px 0px 0px 8px;
        margin: 8px 0px 0px 8px;
    }

}
</style>
  