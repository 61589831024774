<template>
  <div class="pill-component" @click="emitText">
    {{ text }}
  </div>
</template>

<script>
export default {
props: {
  text: {
    type: String,
    required: true
  }
},
methods: {
  emitText() {
    this.$emit('clicked', this.text);
  }
}
};
</script>

<style scoped>
.pill-component {
background-color: #3A6B35;
border-radius: 20px;
padding: 8px;
color: white;
width: fit-content;
min-width: 40px;
display: inline-block;
margin-right: 8px;
text-align: center;
font-size: 0.9em;
margin-bottom: 8px;
cursor: pointer; 
}
</style>
