<template>
  <div class="navbar__wrapper">
    <div class="navbar__div">
      <div class="left__div">
        <span class="website__logo"><router-link to="/mainPage">Memoirlens</router-link></span>
        <span class="slogan">Every Shot Holds a Memory</span>
      </div>
      <div class="right__div">
        <span v-if="userStore.loggedInUser" class="website__username">
          {{ userStore.loggedInUser.name }}
        </span>
  
  
        <Icon icon="simple-line-icons:user" width="16px" @click="openLoginPage()" v-if="userStore.loggedInUser"
          :inline="true">
        </Icon>
  
  <!--       <Icon v-if="userStore.loggedInUser" @click="openNewPostPage()" icon="simple-line-icons:cloud-upload" width="16px"
          :inline="true">
        </Icon> -->
  
        <Icon v-if="userStore?.loggedInUser?.login=='guedes'" @click="openNewAlbumPage()" icon="simple-line-icons:calendar" width="16px"
          :inline="true">
        </Icon>
  
        <Icon icon="simple-line-icons:basket" width="16px" :inline="true" :class="{ 'icon-disabled': !userStore?.loggedInUser }">
        </Icon>
  
        <!--       <Icon 
        icon="simple-line-icons:basket-loaded"
        width="16px"
        :inline="true">
        </Icon> -->
  
  
       
  
        <Icon icon="simple-line-icons:info" width="16px" @click="openAboutPage()" :inline="true">
        </Icon>
  
  
        <Icon icon="simple-line-icons:logout" width="16px" v-if="userStore.loggedInUser" @click="logout()" :inline="true">
        </Icon>
  
        <Icon icon="simple-line-icons:login" width="16px" v-if="!userStore.loggedInUser" @click="openLoginPage()"
          :inline="true">
        </Icon>
  
        <Icon icon="simple-line-icons:home" width="16px" @click="goHome()"
          :inline="true">
        </Icon>      
  
      </div>
  
  
    </div>
  </div>
</template>

<script>
import { useUserStore } from '../../store/user';
import { Icon } from '@iconify/vue'
//import router from '../routes';
import { useRouter } from 'vue-router';
import { usePostStore } from '../../store/post';

//https://icones.js.org/collection/simple-line-icons

export default {
  setup() {
    const userStore = useUserStore();
    const router = useRouter();
    const postStore = usePostStore();
    return {
      router,
      userStore,
      postStore,
    };
  },
  methods: {
    goHome() {
        this.router.push("/mainPage");
    },    
    openLoginPage() {

      if (this.userStore.loggedInUser) {
        this.router.push("/loginForm");
      } else {
        this.router.push("/login");
      }

    },
    openAboutPage() {
        this.router.push("/about");
    },
    openNewPostPage() {

      if (this.userStore.loggedInUser) {
        this.postStore.setSelectedPost(null);
        this.router.push("/addPost");
      } else {
        this.router.push("/login");
      }

    },
    openNewAlbumPage() {

      if (this.userStore.loggedInUser) {
        this.router.push("/addAlbum");
      } else {
        this.router.push("/login");
      }

    },
    logout() {
      this.userStore.logout();
      this.router.push("/login");
    }

  },
  components: { Icon }
};
</script>

<style scoped>
.icon-disabled {
  opacity: 0.5; 
  pointer-events: none; 
  cursor: default; 
}

.navbar__wrapper {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #FFF;
  border-bottom: 1px solid #3A6B35;
}

.navbar__div {
  max-width: 1484px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.left__div {
  flex: 7;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.left__div > span {
  margin-left: 8px;
}

.right__div {
  flex: 3;
  align-self: center;
  text-align: right;
}

.right__div>* {
  padding-right: 8px;
}

.right__div>svg {
  cursor: pointer;
}



.website__logo {
  font-family: Jockey One, Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.4em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #E3B448;
  padding: 0px;
}

.slogan  {
  font-size: 0.8em;
  font-style: italic;
}

.website__logo>a {
  color: #E3B448;
  /* Change the color to your preferred choice */
  text-decoration: none;
}

.website__logo>a:hover {
  color: #E3B448;
  /* Change the hover color to your preferred choice */
  text-decoration: underline;
}

.website__username {
  display: inline-block;
}


@media (max-width:860px) {
  .right__div {
    flex: unset;

  }

  .website__logo {
    font-size: 20px;
  }

  .slogan  {
    display: none;
  }

  .website__username {
    display: none;
  }

}
</style>

