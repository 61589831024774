<template>
    <div class="comments-section">
        <form @submit.prevent="handleSubmit" class="comment-form">
            <input v-model="newComment.name" type="text" placeholder="Your Name" required />
            <input v-model="newComment.email" type="email" placeholder="Your Email (optional - will not be displayed)" />
            <textarea v-model="newComment.comment" placeholder="Your Comment" required></textarea>
            <button type="submit">Submit Comment</button>
        </form>

        <div class="comments-list">
            <h3>Published Comments</h3>
            <div v-for="comment in comments" :key="comment._id" class="bubble">
                <p class="comment-name"><strong>{{ comment.name }}</strong><span v-if="comment.date"> on <strong>{{comment.date}}</strong></span></p>
                <p class="comment-text">{{ comment.comment }}</p>
                <div v-if="canDeleteOrEdit && comment._id !== -1" class="comment-actions">
                    <button @click="hideComment(comment._id)">Hide</button>
                    <button @click="deleteComment(comment._id)">Delete</button>
                </div>
            </div>
        </div>

        <div v-if="canDeleteOrEdit" class="comments-list">
            <h3>Unpublished Comments</h3>
            <div v-for="comment in unpublishedComments" :key="comment._id" class="bubble">
                <p class="comment-text">Album: {{ comment.albumTitle }}</p>
                <p v-if="comment.postTitle" class="comment-text">Post: {{ comment.postTitle }}</p>
                <p class="comment-name"><strong>{{ comment.name }}</strong> ({{ comment.email || 'No email provided' }})</p>
                <p class="comment-text">{{ comment.comment }}</p>
                <div class="comment-actions">
                    <button @click="publishComment(comment._id)">Publish</button>
                    <button @click="hideComment(comment._id)">Hide</button>
                    <button @click="deleteComment(comment._id)">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useCommentsStore } from '../../store/comments';
import { useUserStore } from '../../store/user';
import { computed, reactive, onMounted } from 'vue';

export default {
    props: {
        albumId: {
            type: String,
            required: false,
        },
        postId: {
            type: String,
            required: false,
        },
        canDeleteOrEdit: {
            type: Boolean,
            default: false,
            required: false,
        },
        postDescription: {
            type: String,
            required: false,
        },
        photographer: {
            type: String,
            required: false,
        }, 
        postDate: {
            type: String,
            required: false,
        },                         
    },
    setup(props) {
        const commentsStore = useCommentsStore();
        const userStore = useUserStore();

        const newComment = reactive({
            name: '',
            email: '',
            comment: '',
            albumId: props.albumId || null,
            postId: props.postId || null,
        });

        // const canDeleteOrEdit = computed(() => {
        //     const loggedInUser = userStore.loggedInUser;
        //     return loggedInUser && loggedInUser.login === 'guedes';
        // });

        const fetchComments = async () => {
            await commentsStore.fetchComments(props.albumId, props.postId);
            if (props.canDeleteOrEdit) {
                await commentsStore.fetchUnpublishedComments(props.albumId, props.postId);
            }
        };

        const handleSubmit = async () => {
            await commentsStore.saveOrUpdateComment(newComment);
            newComment.name = '';
            newComment.email = '';
            newComment.comment = '';
            await fetchComments();
            alert('THanks for you comment. It will be published soon :)');
        };

        const publishComment = async (id) => {
            await commentsStore.publishComment(id, userStore.loggedInUser.token);
            fetchComments();
        };

        const hideComment = async (id) => {
            await commentsStore.hideComment(id, userStore.loggedInUser.token);
            fetchComments();
        };

        const deleteComment = async (id) => {
            await commentsStore.deleteComment(id, userStore.loggedInUser.token);
            fetchComments();
        };

        onMounted(() => {
            fetchComments();
        });

        // First comment will always the photographer comment
        const comments = computed(() => {
        const baseComments = commentsStore.comments;
        const additionalInfoComment = {
                _id: -1,
                name: props.photographer || 'Unknown Photographer',
                comment: props.postDescription || 'No description available',
                date: props.postDate,
            };
            return [additionalInfoComment, ...baseComments];
        });

        return {
            newComment,
            comments,
            unpublishedComments: computed(() => commentsStore.unpublishedComments),
            handleSubmit,
            publishComment,
            hideComment,
            deleteComment,
            userStore,
        };
    },
};
</script>

<style scoped>
.comments-section {
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.comment-form {
    margin: 8px 0 8px  0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.comment-form input,
.comment-form textarea {
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    width: calc(100% - 32px);
    border: 1px solid #ddd;
    border-radius: 5px;
}

.comment-form button {
    align-self: flex-end;
    margin-right: 8px;
    padding: 10px 20px;
    border: none;
    background-color: #3A6B35;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}


.bubble {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    width: calc(100% - 24px);
    height: auto;
    padding: 8px;
    margin-bottom: 16px;
    background: #f9f9f9;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px 10px 10px 0;
}


.comment-name {
    /* font-weight: bold; */
    margin-bottom: 5px;
}

.comment-text {
    margin-bottom: 10px;
}

.comment-actions {
    flex-wrap: wrap;
    display: flex;
    gap: 5px;
}

.comment-actions button {
    padding: 5px 10px;
    border: none;
    background-color: #3A6B35;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

@media (max-width:1280px) {

}

@media (max-width:602px) {

    .comment-form input,
    .comment-form textarea {
        flex: auto;
    }

}
</style>