<template>
  <div class="single__div" >
    <div class="album__header">
      <div class="album__info">
        <h3><b>Album Title:</b> Loading...</h3>
      </div>
      <div class="album__calendar">
        <Icon icon="simple-line-icons:calendar" width="24px" :inline="true">
        </Icon>
      </div>
    </div>

    <img src="../assets/loading_album_sign.webp" alt="Loading" class="album__photo" />
  </div>
</template>
  
<script>
import { APISettings } from '../../store/config.js';
import { Icon } from '@iconify/vue';
import { useRouter } from 'vue-router';

export default {
  props: {
  },
  data() {
    return {
    };
  },  
  setup() {
    const baseUrl = APISettings.baseUrl;
    const router = useRouter();

    // Preload the loading image as soon as the component is created
    const preloadImage = () => {
      const img = new Image();
      // Adjust the path to the image as necessary. If the image is static and public,
      // you might want to use an absolute path or adjust according to your build setup.
      img.src = new URL('../assets/loading_album_sign.webp', import.meta.url).href;
    };
    
    // Call the preload function
    preloadImage();    

    return {
      baseUrl,
      router,

    };
  },
  methods: {
    showPhotographerInfo() {
      this.router.push("/about");
    },    
  },
  components: { Icon,  }
};
</script>
  
<style scoped>
.single__div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #FFF;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 0px 16px 16px 16px;
  text-align: left;
  font-size: 0.8em;
  cursor: pointer;
}

.album__photo {
  width: 100%;
  max-width: 602px;
  height: auto;
  border-radius: 8px;

}

.album__header {
  display: flex;
  flex-wrap: wrap;

}

.album__info {
  flex: 99;
}

.album__info > h3 {
  padding: 0px 0px 0px 0px;
}


.album__calendar {
  align-self: center;
  flex: 1;
}

.photographer__info {
  color: #E3B448;
  /* Change the color to your preferred choice */
  text-decoration: none;
  cursor: pointer;

}


/* @media (max-width: 1501px) {
  .album__photo {
    max-width: 502px;
  }

}
 */
 
@media (max-width:860px) { 

  .single__div {
    padding: 0px 8px 8px 8px;
  }
}

@media  (max-width: 1502px) {
  .single__div {
    padding: 0px 8px 8px 8px;
  }
}


@media (max-width:1080px) {

  .single__div {
    padding: 0px 8px 8px 8px;
  }

  .single__div > img {
    margin: auto;
  }

}
</style>
  