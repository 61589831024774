<template>
  <div class="main__area">
    <div class="select-post-parent">

      <div class="local__toobar">
        <Icon icon="simple-line-icons:pencil" width="16px" :inline="true" v-if="canDeleteOrEdit" @click="editPost()">
        </Icon>

        <Icon icon="simple-line-icons:refresh" width="16px" :inline="true" v-if="canDeleteOrEdit" @click="refresh()">
        </Icon>

        <Icon icon="simple-line-icons:trash" width="16px" :inline="true" v-if="canDeleteOrEdit"
          @click="showPostDeleteConfirmation()">
        </Icon>

        <Icon icon="material-symbols-light:html-sharp" width="16px" :inline="true" v-if="canDeleteOrEdit"
          @click="generateStaticPostPage()">
        </Icon>

        <Icon icon="simple-line-icons:share" width="16px" :inline="true" @click="copyShareLink()">
        </Icon>

        <Icon icon="simple-line-icons:arrow-left-circle" width="16px" :inline="true" @click="goBack()">
        </Icon>

      </div>

      <div class="upper-div">
        <h3>Post: {{ selectedPost.title }}</h3>

      </div>
      <div class="big__image__parent">
        <img :src="bigImageUrl(selectedPost.file)" :alt="selectedPost.title" class="big__image" />
      </div>

      <h3 @click="toggleSpecifications" class="specifications_toggle_container">
        Photo specifications
        <Icon :icon="isCollapsed ? 'mdi:chevron-down' : 'mdi:chevron-up'" width="20px" />
      </h3>      

      <div v-if="!isCollapsed"  class="specifications__container">
        <div class="masonry-grid">
          <div class="masonry-item">
            <strong>Album</strong>
            <div>
              {{ selectedPost?.album?.title }}
              <Icon icon="simple-line-icons:link" style="cursor: pointer;" width="16px" @click="handleAlbumClicked()"
                :inline="true">
              </Icon>
            </div>
          </div>

          <div class="masonry-item">
            <strong>ISO</strong>
            <div>{{ selectedPost?.iso }}</div>
          </div>

          <div class="masonry-item">
            <strong>F/Stop</strong>
            <div>{{ selectedPost?.f_stop }}</div>
          </div>

          <div class="masonry-item">
            <strong>Dimensions</strong>
            <div>{{ selectedPost?.dimensions }}</div>
          </div>

          <div class="masonry-item">
            <strong>Exposure</strong>
            <div>{{ selectedPost?.exposure }}</div>
          </div>

          <div class="masonry-item">
            <strong>Camera brand</strong>
            <div>{{ selectedPost?.camera_brand || '-' }}</div>
          </div>

          <div class="masonry-item">
            <strong>Camera model</strong>
            <div>{{ selectedPost?.camera_model || '-' }}</div>
          </div>

          <div class="masonry-item">
            <strong>Focal length</strong>
            <div>{{ selectedPost?.focal_length || '-' }}</div>
          </div>

          <div class="masonry-item">
            <strong>Lens model</strong>
            <div>{{ selectedPost?.lens_model || '-' }}</div>
          </div>

          <div class="masonry-item">
            <strong>Software</strong>
            <div>{{ selectedPost?.software || '-' }}</div>
          </div>

          <div class="masonry-item">
            <strong>Flash</strong>
            <div>{{ selectedPost?.flash || '-' }}</div>
          </div>
        </div>
      </div>

      <div class="upper-div">
        <div class="pill__container">
          <PillComponent v-for="tag in tags" :key="tag + selectedPost.id" :text="tag"></PillComponent>
        </div>

      </div>


      <CommentsComponent :postId="route?.query?.postId" :postDescription="selectedPost.description" :photographer="selectedPost.photographerDetails?.name"
        :postDate="formattedDate"
        :canDeleteOrEdit="canDeleteOrEdit" />

    </div>

    <ConfirmationDialog v-if="isConfirmationVisible" :question="confirmationQuestion" @confirm="deletePost()"
      @cancel="cancelDeletePost()" />

  </div>
</template>

<script>
import { APISettings } from '../../store/config.js';
import { usePostStore } from '../../store/post';
import { useAlbumStore } from '../../store/album';
import { useRoute } from 'vue-router';
import { Icon } from '@iconify/vue';
import { useUserStore } from '../../store/user';
import ConfirmationDialog from '../components/ConfirmationDialog.vue';
import { useRouter } from 'vue-router';
import { onMounted, } from 'vue';
import PillComponent from '../components/PillComponent.vue';
import CommentsComponent from '../components/CommentsComponent.vue'; // Import CommentsComponent
import { ref } from 'vue';

export default {
  computed: {
    tags() {
      return this.selectedPost?.tags?.split(",").map(tag => tag.trim()) || [];
    },
    formattedDate() {
      const date = new Date(this.selectedPost?.created);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },
    selectedPost() {
      const postStore = usePostStore();
      return postStore.selectedPost;
    },
    canDeleteOrEdit() {
      const loggedInUser = this.userStore?.loggedInUser;
      const photographerId = this.selectedPost?.photographer?._id || this.selectedPost?.photographerDetails?._id;
      const userId = loggedInUser?.id;

      return loggedInUser && userId == photographerId;
    },
  },
  data() {
    return {
      showInfo: false,
      isConfirmationVisible: false,
      confirmationQuestion: "Are you sure?",
    };
  },
  setup() {
    const baseUrl = APISettings.baseUrl;
    const route = useRoute();
    const postStore = usePostStore();
    const userStore = useUserStore();
    const router = useRouter();
    const albumStore = useAlbumStore();

    const fetchPostIfNeeded = async () => {
      // Assuming postId is a parameter in the URL
      const postId = route?.query?.postId;

      // Check if selectedPost is not available but postId is
      if ((!postStore.selectedPost?.album || !postStore.selectedPost?._id) && postId) {
        await postStore.retrievePostById(postId);
      }
    };

    onMounted(() => {
      fetchPostIfNeeded();
    });

    const isCollapsed = ref(true); // Collapsed by default

    const toggleSpecifications = () => {
      isCollapsed.value = !isCollapsed.value;
    };    

    return {
      baseUrl,
      route,
      postStore,
      userStore,
      router,
      albumStore,
      isCollapsed,
      toggleSpecifications,

    };
  },
  methods: {
    async refresh() {
      const postId = this.route?.query?.postId;
      await this.postStore.retrievePostById(postId);
    },
    async handleAlbumClicked() {
      this.selectedPost.album.photographer = this.selectedPost?.photographerDetails;
      this.albumStore.selectedAlbum = this.selectedPost.album;
      this.postStore.posts = [];
      this.router.push({ path: "viewAlbum", query: { albumId: this.selectedPost?.album?._id } });

    },
    async editPost() {
      this.router.push("/editPost");
    },
    goBack() {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        this.$router.push('/mainPage');
      }
    },
    showPostDeleteConfirmation() {
      this.isConfirmationVisible = true;
      this.confirmationQuestion = "Are you sure you want to delete this post?";
    },
    bigImageUrl(file) {
      if (file) {
        return this.baseUrl + file.replaceAll('resized_', '');
      }
      return "";
    },
    async deletePost() {
      const postId = this.route?.query?.postId;
      await this.postStore.deletePost(postId, this.userStore?.loggedInUser?.token);
      this.isConfirmationVisible = false;
      this.router.push("/mainPage");
    },
    cancelDeletePost() {
      this.isConfirmationVisible = false;
    },
    async generateStaticPostPage() {
      const postFormData = new FormData();
      const postId = this.route?.query?.postId;
      postFormData.append("postId", postId);
      await this.postStore.generateStaticPostPage(postFormData, this.userStore?.loggedInUser?.token)
        .then(() => {
          // This will open the post in a new tab after the post generation is successful
          window.open(`/public/post-${postId}.html`, '_blank');
        })
        .catch(error => {
          // Handle any errors that occur during the post generation
          console.error("Error generating static post page:", error);
        });
    },
    async copyShareLink() {
      const postFormData = new FormData();
      const postId = this.route?.query?.postId;
      postFormData.append("postId", postId);

      try {
        await this.postStore.generateStaticPostPage(postFormData, this.userStore?.loggedInUser?.token);
        await this.copyText(`https://memoirlens.com/public/post-${postId}.html`);
      } catch (error) {
        // Handle any errors that occur during the post generation
        console.error("Error generating static post:", error);
      }
    },
    async copyText(textToCopy) {
      try {
        // Check if the Clipboard API is available
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(textToCopy);
          alert('Share link copied to clipboard!');
        } else {
          // Fallback for older browsers
          alert('Share link copy failed!');
        }
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
  },
  components: {
    Icon,
    ConfirmationDialog,
    PillComponent,
    CommentsComponent // Register CommentsComponent
  },
};
</script>

<style scoped>
.main__area {
  background-color: #CBD18F;
  padding: 8px;
  min-height: 100vh;
}

.upper-div {
  text-align: left;
  padding: 8px;
}

.select-post-parent {
  display: flex;
  background-color: #FFF;
  border-radius: 8px;
  flex-wrap: wrap;
  max-width: 1485px;
  margin: auto;
  flex-direction: column;
}

.local__toobar {
  display: flex;
  float: right;
  margin-left: auto;
  padding: 8px;
}

.local__toobar>svg {
  margin: 4px;
  cursor: pointer;
}

.big__image__parent {
  display: flex;
  padding: 8px;
  align-self: center;
}

.big__image__parent>img {
  width: 100%;
  max-width: 1468px;
  height: auto;
  border-radius: 8px;
}

h3 {
  margin: 8px 12px;
  text-align: left;
}

.specifications_toggle_container > svg {
  cursor: pointer; /* Hand cursor style */
}

h3 Icon {
  margin-left: 8px;
}

p {
  margin: 0;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

cite {
  margin-top: 12px;
  display: block;
  font-style: italic;
  text-align: right;

  &:before {
    content: "- ";
  }
}

table {
  margin-right: auto;
  padding-left: 8px;
  padding-bottom: 8px;
}

th {
  text-align: left;
}

td {
  text-align: left;
  width: 50%;
}

.pill__container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;
}

.pill-component {
  cursor: default;
}

.specifications__container {
  margin: 8px 8px 8px 8px;
}


.masonry-grid {
  column-count: 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.masonry-item {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width:1280px) {
  .masonry-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width:602px) {
  .masonry-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
