/* eslint-disable */
import { defineStore } from 'pinia';
import axios from 'axios';
import { APISettings } from './config.js';

export const useMessageStore = defineStore('message', {
    actions: {
      async sendMessage({ subject, email, phone, message, recaptchaToken }) {
        try {
          // eslint-disable-next-line no-unused-vars
          const response = await axios.post(`${APISettings.baseUrl}/api/message/sendMessage`, {
            subject,
            email,
            phone,
            message,
            recaptchaToken  
          });
          // alert('Message sent successfully!');
          return true;
        } catch (error) {
          console.error('Failed to send message:', error);
          // alert('Failed to send message');
          return false;
        }
      }
    }
  });
