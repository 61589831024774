<template>
  <div>
    <h1>Login</h1>
    <!-- Conte�do do componente -->
  </div>
</template>

<script>
export default {
  // L�gica do componente
}
</script>

<style scoped>
/* Estilos do componente */
</style>
