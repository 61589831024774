<template>
  <div class="post-parent-layout">

    <div class="post-left-div">
      <img :src="imageToShow" alt="Loading" class="post__photo" />

      <div class="action__icons">
        <Icon :icon="iconToShow" width="16px" :inline="true">
        </Icon>

        <Icon :icon="iconToShow" width="16px" :inline="true">
        </Icon>

        <Icon :icon="iconToShow" width="16px" :inline="true">
        </Icon>

        <Icon :icon="iconToShow" width="16px" :inline="true">
        </Icon>

        <Icon :icon="iconToShow" width="16px" :inline="true">
        </Icon>

        <Icon :icon="iconToShow" width="16px" :inline="true">
        </Icon>

        <Icon :icon="iconToShow" width="16px" :inline="true">
        </Icon>
      </div>



    </div>
    <div class="post-right-div">
      <h3 v-if="!!notFound">Not found :(</h3>
      <h3 v-else>Loading...</h3>
      <p v-if="!!notFound">We could not find any post or photo with the search text that you specified.</p>
      <p v-else>The images are being loaded. Hold on :)</p>

    </div>



  </div>
</template>

<script>
import { APISettings } from '../../store/config.js';
import { Icon } from '@iconify/vue'
import { useUserStore } from '../../store/user';
import { usePostStore } from '../../store/post';
import { useRouter } from 'vue-router';
import notFoundImage from '../assets/not_found.jpg';
import loadingSignImage from '../assets/loading_sign.webp';


export default {
  props: {
    notFound: {
      type: Boolean,
      required: false
    },     
  },
  data() {
    return {
      isConfirmationVisible: false,
      confirmationQuestion: "Are you sure?",
    };
  },
  setup(props) {
    const baseUrl = APISettings.baseUrl;
    const userStore = useUserStore();
    const postStore = usePostStore();
    const router = useRouter();

    const imageToShow = props.notFound ? notFoundImage : loadingSignImage;
    const imageToPreload = props.notFound ? '../assets/not_found.jpg' : '../assets/loading_sign.webp';

    // Preload the loading image as soon as the component is created
    const preloadImage = () => {
      const img = new Image();
      img.src = new URL(imageToPreload, import.meta.url).href;
    };

    const iconToShow = props.notFound ? 'octicon:squirrel-24' : 'icon-park-outline:loading';

    // Call the preload function
    preloadImage();

    return {
      baseUrl,
      userStore,
      postStore,
      router,
      imageToShow,
      imageToPreload,
      iconToShow,

    };
  },
  computed: {
    tags() {
      return ["loading"]
    },
    formattedDate() {
      const date = new Date();
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },
  },
  methods: {
    showPhotographerInfo() {
      this.router.push("/about");
    },
  },
  components: { Icon,  }
};
</script>

<style scoped>
.post-parent-layout {
  display: flex;
  background-color: #FFF;
  border-radius: 8px;
  flex-wrap: wrap;
  padding: 8px;
  margin-top: 4px;

}


.post-left-div {
  text-align: left;
  padding: 8px;
  max-width: 502px;
  margin: auto;
}

.post-left-div>svg {
  cursor: pointer;
}

.action__icons {
  padding: 8px 8px 0px 0px;


}

.action__icons>svg {
  padding-left: 8px;
  cursor: pointer;
}

.post__photo {
  width: 100%;
  max-width: 602px;
  height: auto;
  border-radius: 8px;
  display: flex;
}

.post-right-div {
  text-align: left;
  padding: 0px 8px 8px 0px;
  font-size: 0.8em;
  max-width: 302px;
  width: fit-content;
  min-width: 302px;
}

.photographer__info {
  color: #E3B448;
  /* Change the color to your preferred choice */
  text-decoration: none;
  cursor: pointer;

}

.post-right-div>p {
  margin: 8px;

}

h3 {
  padding: 0px 0px 0px 8px;
}

.pill__container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;
}

@media (min-width:603px) {
  .post-left-div {
    margin-top: 8px;
  }

}


@media (min-width:603px) {
  .post-left-div {
    margin-top: 8px;
  }  

}


@media (min-width: 1080px) and (max-width: 1501px) {

  .post-right-div {
    padding: 0px;
  }  

  .post__photo {
    max-width: 502px;
  }

  .post-parent-layout {
    display: flex;
    flex-direction: column;
  }

  .post-left-div {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    max-width: 502px;
  }  


  .post-parent-layout {
    padding: 4px;

  }


  .post-left-div {
    padding: 4px;
  }


}

@media  (min-width:1150px) and (max-width: 1280px) {
  .post-parent-layout {
    margin-right: 8px;
  }
}


@media (min-width:603px) {
  .post-left-div {
    margin-top: 8px;
  }  

}
</style>