<template>
    <div class="confirmation-dialog">
      <!-- div class="overlay" @click="cancel"></div> -->
      <div class="modal">
        <p>{{ question }}</p>
        <button @click="confirm">Yes</button>
        <button @click="cancel">No</button>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent,  } from 'vue';
  
  export default defineComponent({
    props: {
      question: {
        type: String,
        required: true,
      },
    },
    methods: {
      confirm() {
        this.$emit('confirm');
      },
      cancel() {
        this.$emit('cancel');
      },
    },
  });
  </script>
  
  <style scoped>
    .confirmation-dialog {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
  
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  
    .modal {
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .modal button {
      margin-right: 10px;
    }
  </style>
  