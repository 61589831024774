<template>
  <div class="login__area">

    <div class="inner__login_area">
      <div class="login__form">
        <input v-model="loginForm.login" type="text" placeholder="Login">
        <input v-model="loginForm.password" type="password" placeholder="Password">
        <button @click="login">Login</button>
        <p><router-link to="/loginForm">Create new login</router-link></p>
        <p v-if="errorMessage" class="error__message">{{ errorMessage }}</p>
      </div>

      <div class="site__text">
        <h3>Hey there, welcome to MemoirLens! This is where the magic of moments comes to life.</h3>

        <p>Let me take you on a journey with our photos. We're all about capturing those amazing close-up shots with
          macro photography and the cool, unexpected moments of street photography from our travels. We've got a bunch
          of photos that really show off the beauty of the tiny details in nature and the diverse vibes of cities and
          people from all around the world.</p>

        <p>I'm super passionate about getting up close with nature, seeing all the cool little things like colorful
          flowers and tiny bugs that we usually miss. And when I'm not doing that, I'm out traveling, camera in hand,
          snapping pics of different places and faces. It's all about catching those real, raw moments that tell a
          story.</p>

        <p>Want to get creative? We've got you. You can grab the original RAW files of our photos to edit and play
          around with. It's perfect for anyone who loves diving into the nitty-gritty of photo editing to make each
          picture their own masterpiece.</p>

        <p>Looking for something special? Whether you're decorating your place, adding to your art collection, or just
          love browsing beautiful photos, we've got a ton of cool shots for you to check out. Find something that speaks
          to you in our galleries.</p>

        <p>Discover the Perfect Piece:<br>
          Whether you're an art enthusiast, a designer, or simply someone who appreciates the beauty of photography,
          MemoirLens offers a wide selection of captivating images to suit your taste. Browse through our carefully
          curated
          galleries and find the perfect piece that resonates with you.</p>

        <p>Come join us and see the world through our lens. Each photo has its own story, and we're excited to share these moments with you.</p>

        <p>Ready to start your photo adventure? Let's go!</p>
      </div>

    </div>



  </div>
</template>

<script>
import { useUserStore } from '../../store/user';
import { useRouter } from 'vue-router';

export default {
  name: 'LoginPage',
  data() {
    return {
      errorMessage: null,
      loginForm: {
        login: '',
        password: '',
      }
    };
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();

    return {
      userStore,
      router,
    };
  },
  methods: {
    async login() {
      // eslint-disable-next-line no-unused-vars
      grecaptcha.ready(() => {
        grecaptcha.execute('6Ld_uZspAAAAAOwcp6W8xAwXQ_vZJzuYMLGc5gnh', { action: 'login' }).then((token) => {
          const loginData = {
            ...this.loginForm,
            recaptchaToken: token,
          };
          this.processLogin(loginData);
        });
      });
    },    
    async processLogin(loginData) {
      const success = await this.userStore.validateLogin(loginData);

      if (success) {
        // Login bem-sucedido, redirecionar para a página principal ou realizar outra ação
        this.errorMessage = null;
        this.router.push("/mainPage");
      } else {
        this.errorMessage = "Login failed"
      }
    },
  }
};
</script>

<style scoped>
.error__message {
  color: red;
}

.login__area {

  background-color: #CBD18F;
  padding: 8px;
  min-height: 100dvw;
}

.inner__login_area {
  display: flex;
  flex-wrap: wrap;
  max-width: 1508px;
  margin: auto;
  justify-content: center;
  margin-top: 4px;

}

.site__text {
  background-color: #FFF;
  border-radius: 8px;
  padding: 8px;
  text-align: left;

}

input {
  width: 100%;
  max-width: 200px;
  margin-bottom: 8px;
}

.login__form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: #FFF;
  border-radius: 8px;
  padding: 8px;
  max-width: 1508px;
  width: 100%;
  margin-bottom: 8px;

}

.login__form>* {
  padding: 8px;
  margin-right: 8px;
}

@media (max-width:300px) { 
  input {
    max-width: 80%;
  }
}
</style>
