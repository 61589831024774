<template>
  <div class="about__area">

    <div class="inner__about_area">
      <div class="site__text__wrapper">
          <div class="local__toobar">
            <Icon icon="simple-line-icons:arrow-left-circle" width="16px" :inline="true"  @click="goBack()"></Icon>

          </div>
          <div class="site__text">
            <img class="image" src="../assets/about_page_photo.jpg" />
    
            <h3>Welcome to Memoirlens</h3>
    
            <div class="text">
              <p><i>Every Shot Holds a Memory</i>: This slogan communicates the idea that each photograph captured and
                displayed on my site is more than
                just an image. It represents a moment frozen in time, a memory that has been immortalized through the lens
                of my camera. </p>
    
              <p>About me:</p>
              <p>I’m a software engineer who’s really into photography. I’ve combined my tech skills and love for photos to
                create this site. It’s built with <span class="text__highlight">Vue JS 3</span> and <span
                  class="text__highlight">Node JS</span>, with <span class="text__highlight">MongoDB</span> keeping all our
                data in order. Plus,
                it’s all running smoothly on an <span class="text__highlight">AWS EC2</span> instance.</p>
    
              <p>Photography for me is about exploring and capturing the world. Whether I’m wandering city streets or
                getting up close with macro photography, I’m all about snapping the diverse and beautiful moments around us.
                Each photo, from street scenes to the tiny wonders caught by my macro lens, is a peek into different stories
                and places.</p>
    
              <p>What awaits you here:</p>
              <p>I’m sharing my shots taken with a macro lens and a <span class="text__highlight">35mm Sony lens</span>, but you’ll see some others too. I edit
                and resize these photos for the web, but I’ve got the high-quality RAW files if you’re interested.</p>
    
              <p>This site isn’t about selling stuff, but if a photo catches your eye, we can chat about it. Signing up
                gives you access to cool extra features, too.
              </p>
    
              <p>So, start your photo adventure with MemoirLens today! Let’s dive into the beauty of the world together, one
                snapshot at a time. Try to search photos with tags like "macro photography", "street photography". Just try
                and I am sure you'll find the type of photo you need.</p>
    
              <p>Be sure to follow me on my social networks for more news.</p>
    
              <p>Cordially,</p>
              <p>Cristian Guedes</p>
    
              <Icon icon="simple-line-icons:social-instagram" width="16px" :inline="true"></Icon>&nbsp;<a class="socialmedia-link"
                href="https://www.instagram.com/memoirlens2024/" target="_blank" rel="noopener noreferrer">
                @moirlens2024</a>&nbsp;
              <Icon icon="simple-line-icons:social-youtube" width="16px" :inline="true"></Icon>&nbsp;<a  class="socialmedia-link"
                href="https://www.youtube.com/c/CristianSGuedes" target="_blank" rel="noopener noreferrer">
                @CristianSGuedes</a>
            </div>
    
          </div>

      </div>

      <div class="contact__form">
        <div class="contact__form__input" v-if="!messageStatus && messageStatus!==false">
          <p>If you have any questions, please do not hesitate to contact me. I check my inbox frequently.</p>
          <form @submit.prevent="sendMessage">
            <div>
              <label for="subject">Subject:</label>
              <input id="subject" type="text" v-model="form.subject" required />
            </div>
            <div>
              <label for="email">Email:</label>
              <input id="email" type="email" v-model="form.email" required />
            </div>
            <div>
              <label for="phone">Phone:</label>
              <input id="phone" type="tel" v-model="form.phone" />
            </div>
            <div>
              <label for="message">Message:</label>
              <textarea id="message" v-model="form.message" required></textarea>
            </div>
            <button type="submit">Send</button>
          </form>

        </div>
        <div v-if="messageStatus===true" class="success-message">
          <h3>Thank you for reaching out!</h3>
          <p>Your message has been sent successfully. I really appreciate you taking the time to write, and I’m looking forward to reading what you have to share. I strive to respond to all inquiries within 48 hours, so you’ll hear back from me soon.</p>
          <p>In the meantime, feel free to browse through my gallery for more visual inspiration!</p>
          <p class="signature">Warm regards,<br>Cristian Guedes</p>
        </div>
        <div v-if="messageStatus===false" class="error-message">
          <h3>Oops! Something went wrong.</h3>
          <p>We're sorry, but your message couldn't be sent at this time. Please try again later. We're here to help!</p>
          <p>Thank you for your patience.</p>
          <p><button type="button" @click="messageStatus=null">Try again</button></p>
        </div>

        <div class="contact__image__wrapper">
          <img class="contact__image" :src="whatImage" />
        </div>
      </div>      
      

      <div class="about__footer">
        <img class="site__logo" src="../assets/android-chrome-192x192.png" />
        <span>Memoirlens</span>
        <span>Every Shot Holds a Memory</span>
      </div>

    </div>



  </div>
</template>

<script>
import { useUserStore } from '../../store/user';
import { useRouter } from 'vue-router';
import { Icon } from '@iconify/vue'
import { ref } from 'vue';
import { useMessageStore } from '../../store/message';
import successImage from '@/assets/message_sent.jpg';
import failedImage from '@/assets/message_failed.jpg';
import defaultImage from '@/assets/contact_image_3.jpg';

export default {
  name: 'AboutMe',
  data() {
    return {
      errorMessage: null,
      loginForm: {
        login: '',
        password: '',
      }
    };
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();

    const form = ref({
        subject: '',
        email: '',
        phone: '',
        message: ''
      });
  
      const messageStore = useMessageStore();
      const messageStatus = ref(null);
  
      async function sendMessage() {
        // eslint-disable-next-line no-unused-vars
        grecaptcha.ready(async () => {
            const recaptchaToken = await grecaptcha.execute('6Ld_uZspAAAAAOwcp6W8xAwXQ_vZJzuYMLGc5gnh', {action: 'submit'});
            const result = await messageStore.sendMessage({...form.value, recaptchaToken});
            messageStatus.value = result;
            // this.router.push("/mainPage");
        });


      }    

    return {
      userStore,
      router,
      form,
      sendMessage,
      messageStatus,
    };
  },
  computed: {
    whatImage() {

      if (this.messageStatus === true) {
        return successImage;

      } else if (this.messageStatus === false) {
        return failedImage;

      }

      return defaultImage;
    },

  },    
  methods: {
    goBack() {
      window.history.back();
    },
  },
  components: { Icon }
};
</script>

<style scoped>
/* Success message styling */
.success-message {
  flex: 1;
  margin: 20px auto; 
  text-align: left; 
}

/* Styling the body text for readability */
.success-message p {
  font-size: 16px; 
  line-height: 1.6;
  margin: 10px 0; 
}

/* Styling signature or closing line */
.success-message .signature {
  font-style: italic; 
  margin-top: 20px; 
  font-size: 16px;
}

.error-message {
  flex: 1;
  margin: 20px auto; 
  text-align: left; 
}




.local__toobar {
  display: flex;
  /* float: right; */
  margin-left: auto;
  justify-content: flex-end;
  padding: 8px;
}

.local__toobar  > svg {
  margin: 4px;
  cursor: pointer;
}


.socialmedia-link {
  text-decoration: none; /* No underline by default */
  color: inherit; /* Inherits the color from parent or defaults to the initial color */
}

.socialmedia-link:hover {
  color: #3A6B35; /* Changes the text color on hover */
  text-decoration: underline; /* Underlines the text on hover */
}

.image {
  display: flex;
  width: 100%;
  height: auto;
  max-width: 400px;
  max-height: 400px;
  float: left;
  margin-right: 8px;
}

.contact__image__wrapper {
  display: flex;
}

.contact__image {
  display: flex;
  width: 100%;
  height: auto;
  max-width: 400px;
  max-height: 400px;
  margin: 8px;
}

.text__highlight {
  font-size: 1.2em;
  font-weight: bold;
}


.about__me__container>.text {
  text-align: justify;
}

.about__area {
  background-color: #CBD18F;
  padding: 4px;
}

.inner__about_area {
  display: flex;
  flex-wrap: wrap;
  max-width: 1484px;
  margin: auto;
  justify-content: center;
  margin-top: 4px;
  height: auto;


}

.site__text__wrapper {
  background-color: #FFF;
  border-radius: 8px;
}

.contact__form {
  margin: 8px 0 8px  0;
  width: 100%;
  background-color: #FFF;
  border-radius: 8px;
  padding: 8px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.contact__form__input {
  flex: 1;
}

textarea,
input {
  width: calc(100% - 8px);
  margin: 8px 0 8px  0;
  
}

.site__text {
  padding: 8px;
  text-align: left;
  height: auto;

}

.about__footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin: 8px;
}

.about__footer > span {
  color: #3A6B35;
}

.about__footer > span:nth-child(2) {
  font-size: 1.2em;
}

.about__footer > span:nth-child(3) {
  font-size: 0.8em;
}

.site__logo {
  width: 100%;
  height: auto;
  max-width: 64px;
  margin: 8px;
}

@media (max-width:900px) {
  .contact__form__input {
    flex: auto;
  }

  .contact__image {
    margin-left: auto;
    margin-right: auto;
  }

  .contact__image__wrapper {
    flex: auto;
  }

}


@media (max-width:700px) {
  .image {
    float: none;
    align-items: center;
  }

  .site__text>img {
    margin: auto;

  }

  h3 {
    padding: 0px 0px 0px 0px;
  }
}
</style>
