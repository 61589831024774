// Import required libraries
import axios from 'axios';
import { defineStore } from 'pinia';
import { APISettings } from './config.js';

// Create the store
export const useUploadStore = defineStore('upload', {
  state: () => ({
    uploadError: null,
    responseData: null,
  }),
  actions: {
    async chunkUpload(file, formData, token) {

      const chunkSize = 1 * 1024 * 1024; // 1MB chunk size, adjust as needed
      const totalChunks = Math.ceil(file.size / chunkSize);
      try {
        for (let index = 0; index < totalChunks; index++) {
          const chunk = file.slice(index * chunkSize, (index + 1) * chunkSize);

          const completeFormData = new FormData();

          for (const [key, value] of formData.entries()) {
            completeFormData.append(key, value);
          }

          completeFormData.append('chunk', chunk, `${file.name}-chunk${index}.part`);
          //completeFormData.append('chunk', chunk);
          completeFormData.append('chunkIndex', index);
          completeFormData.append('totalChunks', totalChunks);
          completeFormData.append('originalname', file.name);
          
          const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          };

          // for (const [key, value] of completeFormData.entries()) {
          //   console.log(key, value);
          // }

          const response = await axios.post(`${APISettings.baseUrl}/api/uploadService/chunkUpload`, completeFormData, { headers });

          if (!response.data.success) {
            throw new Error('Failed to upload chunk');
          }

          this.responseData = response?.data;

        }

        // All chunks have been uploaded successfully
        this.uploadError = null;
        console.log('File uploaded successfully through chunks');
      } catch (error) {
        console.error('Failed to upload the file through chunks:', error);
        this.uploadError = 'Failed to upload the file';
      }
    },

    async uploadFile(data, token) {
      try {

        const headers = {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + token
        };

        const requestOptions = {
          method: 'POST',
          headers: headers,
          data: data
        };
  
        const response = await axios.post(APISettings.baseUrl + '/api/uploadService/upload', data, requestOptions);
        if (response.data.success) {
          // File uploaded successfully
          this.uploadError = null;
        } else {
          // File upload failed
          this.uploadError = 'Failed to upload the file';
        }
      } catch (error) {
        console.error('Failed to upload the file:', error);
        this.uploadError = 'Failed to upload the file';
      }
    }
  }
});
