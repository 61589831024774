import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import router from './routes';

createApp(App)
.use(createPinia())
.use(router)
.mount('#app')


// import Vue from 'vue';
// import App from './App.vue';
// import VueRouter from 'vue-router';
// import { createPinia } from 'pinia';
// import { useUserStore } from '../store/user';
// import { usePostStore } from '../store/post';
// import router from './routes';

// Vue.use(VueRouter);


// const pinia = createPinia();
// pinia.use(store => {
//   store.useUserStore = useUserStore;
//   store.usePostStore = usePostStore;
// });

// new Vue({
//   router,
//   pinia,
//   render: h => h(App)
// }).$mount('#app');
