<template>
  <div class="main__area">
    <SearchAlbum :filter="filter" @startSearch="startSearch($event)"></SearchAlbum>
    <div class="parent-layout">
      <div class="left-div">
        <template v-if="!posts?.length">
          <PostPlaceHolderComponent
            :notFound="numberOfRecords === 0 && filter?.length"
            v-for="item in Array.from({ length: filter?.length ? 1 : 3 })"
            :key="item"></PostPlaceHolderComponent>
        </template>
        <PostComponent @pillClicked="pillClicked"  v-for="post in posts" :key="post.id" :data="post" :page="page"></PostComponent>
        <PaginationComponent v-if="posts?.length" :initialPage = "page" :word="filter" :postsPerPage="postsPerPage" :maxDisplayedPages="9" :numberOfRecords="numberOfRecords" :itemsPerPage="postsPerPage" @pageClicked="handlePageClicked" />
      </div>
      <div class="right-div">
        <template v-if="!albums?.length">
          <AlbumPlaceHolderComponent
            v-for="item in Array.from({ length: 3 })"
            :key="item"></AlbumPlaceHolderComponent>          
        </template>
        <SimplifiedAlbumComponent  v-for="album in albums" :key="album.id" :data="album"  @albumClicked="handleAlbumClicked"></SimplifiedAlbumComponent>
        <PaginationComponent :initialPage = "albumPage" v-if="albums?.length" :postsPerPage="postsPerPage" :maxDisplayedPages="9" :numberOfRecords="numberOfAlbums" :itemsPerPage="albumsPerPage" @pageClicked="handleAlbumPageClicked" />
      </div>

    </div>

    <div class="about__footer">
        <img class="site__logo" src="../assets/android-chrome-192x192.png" />
        <span>Memoirlens</span>
        <span>Every Shot Holds a Memory</span>
      </div>

  </div>
</template>

<script>
import { useAlbumStore } from '../../store/album';
import { usePostStore } from '../../store/post';
import { APISettings } from '../../store/config.js';
import PostComponent from './PostComponent.vue'
import PostPlaceHolderComponent from './PostPlaceHolderComponent.vue'
import AlbumPlaceHolderComponent from './AlbumPlaceHolderComponent.vue'
import SimplifiedAlbumComponent from '../components/SimplifiedAlbumComponent.vue'
import SearchAlbum from '../components/SearchAlbum.vue'
import PaginationComponent from '../components/PaginationComponent.vue';
import { useRouter } from 'vue-router';

export default {
  data() {
    return {
      page: 1,
      albumPage: 1,
      postsPerPage: 5,
      albumsPerPage: 4,
      filter: null,
      currentAlbum: null,
    };
  },
  components: {
    PostComponent,
    SimplifiedAlbumComponent,
    SearchAlbum,
    PaginationComponent,
    PostPlaceHolderComponent,
    AlbumPlaceHolderComponent,
  },
  computed: {
    albums() {
      const albumStore = useAlbumStore();
      return albumStore.albums;
    },
    posts() {
      const postStore = usePostStore();
      return postStore.posts;
    },    
    numberOfRecords() {
      const postStore = usePostStore();
      return postStore.numberOfRecords;
    },     
    numberOfAlbums() {
      const albumStore = useAlbumStore();
      return albumStore.numberOfRecords;
    },     
  },
  setup() {
    const albumStore = useAlbumStore();
    const postStore = usePostStore();
    const baseUrl = APISettings.baseUrl;
    const router = useRouter();


    return {
      baseUrl,
      albumStore,
      postStore,
      router
      
    };
  },
  methods: {
    pillClicked(text) {
      this.filter = text;
      console.log("pillClicked from main", text);
    },
    startSearch(filter) {
      this.page = 1;
      this.filter = filter;
      this.retrievePosts(this.page, this.postsPerPage, this.filter);
    },
    async retrievePosts(page, itemsPerPage, filter) {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute('6Ld_uZspAAAAAOwcp6W8xAwXQ_vZJzuYMLGc5gnh', { action: 'retrieve_posts' });
        await this.postStore.retrievePosts(page, itemsPerPage, filter, this.albumId, token);
      });
    },
    handlePageClicked(whatPage) {
      this.page = whatPage;
      this.retrievePosts(this.page, this.postsPerPage, this.filter);
    },
    handleAlbumPageClicked(whatPage) {
      this.albumPage = whatPage;
      this.albumStore.retrieveAlbums(this.albumPage, this.albumsPerPage);
    },    
    async handleAlbumClicked(whatAlbum) {
      this.postStore.lastPage = this.page;
      this.albumStore.lastPage = this.albumPage;
      this.albumStore.selectedAlbum = whatAlbum;
      this.postStore.posts = [];
      this.router.push({path: "viewAlbum", query: {albumId: whatAlbum?._id}});

    },

  },
  mounted() {
    const postStore = usePostStore();

    if (postStore?.lastPage) {
      this.page = postStore?.lastPage;
      this.postStore.lastPage = null;
    }

    if (this.albumStore?.lastPage) {
      this.albumPage = this.albumStore.lastPage;
      this.albumStore.lastPage = null;
    }

    this.albumStore.retrieveAlbums(this.albumPage, this.albumsPerPage);
    this.retrievePosts(this.page, this.postsPerPage, this.filter);
  }
};
</script>

<style  scoped>

.parent-layout {
    display: flex;
    flex-wrap: wrap;
    max-width: 1508px;
    margin: auto;
    justify-content: center;
    margin-top: 4px;
  }

  .left-div {
    border-radius: 24px;
  }

  .left-div > div {
    margin-bottom: 8px;
    
  }

  .right-div {
    border-radius: 8px;
    margin-left: 8px;
    margin-top: 4px;
    text-align: left;
  }


  .main__area {
    background-color: #CBD18F;
    padding: 8px;
    
  }

  div.right-div > div.outer-div {
    margin-left: 16px;
    margin-top: 8px;
  }

  .bottom-div {
    max-width: 1508px;
    margin: auto;
  }

.about__footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin: 8px;
}

.about__footer > span {
  color: #3A6B35;
}

.about__footer > span:nth-child(2) {
  font-size: 1.2em;
}

.about__footer > span:nth-child(3) {
  font-size: 0.8em;
}

.site__logo {
  width: 100%;
  height: auto;
  max-width: 64px;
  margin: 8px;
}

  @media (max-width:1280px) {
    .left-div {
      margin-left: 0px;
      /* width: 100%; */
    }

    .right-div {
      margin-left: 0px;
      /* width: 100%; */
    }

  }

  /* when the right column is displayed below the left column */
  @media (max-width:1501px) {

  }

</style>
