<template>
  
  <NavBar></NavBar>
  <!-- <MainPage/> -->
  <router-view />
</template>

<script>
//import MainPage from './components/MainPage.vue'
import NavBar from './components/NavBar.vue';

export default {
  name: 'App',
  components: {
    //MainPage,
    NavBar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0px;
}

html, body {
  margin: 0px !important;
  padding: 0px !important;
}
</style>
